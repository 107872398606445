/* eslint-disable no-param-reassign */
import Vue from 'vue';

Vue.use({
	// eslint-disable-next-line no-shadow
	install(Vue) {
		const locale = 'Ru-ru';
		// const locale = undefined;

		const rub = new Intl.NumberFormat(locale, {
			style: 'currency',
			currency: 'RUB',
			minimumFractionDigits: 0,
		});
		Vue.prototype.$rub = (value) => `${rub.format(value)}`;

		const points = new Intl.NumberFormat(locale);
		Vue.prototype.$points = (value) => `${points.format(value)}`;
		Vue.prototype.$points_int = (value) => `${points.format(Math.floor(value))}`;
	},
});
