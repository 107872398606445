function getHeaders ({ app }) {
	const headers = {}

	if (app.$auth) {
		const strategy = app.$auth.strategy
		const tokenName = strategy.options.tokenName || 'Authorization'
		const token = app.$auth.getToken(strategy.name)

		if (token) {
			headers[tokenName] = token
		}
	}

	return headers
}

export default (ctx, inject) => {
	const options = {"broadcaster":"pusher","plugins":null,"authModule":true,"connectOnLogin":false,"disconnectOnLogout":false,"key":"bc655cdcc8ed83c91acc","cluster":"ap2","authEndpoint":"https:\u002F\u002Fsk.skolkovo.ru\u002Fapi\u002Fbroadcasting\u002Fauth"}
	options.auth = options.auth || {}
	options.auth.headers = {
		...options.auth.headers,
		...getHeaders(ctx)
	}

	let echo = null;
	const init = async () => {
		if (!echo) {
			const [echo, broadcaster] = await Promise.all([
				import('laravel-echo'),

				await import('pusher-js')

			]);

			window.Pusher = broadcaster

			echo = new Echo(options);
		}

		return echo;
	}

	if (ctx.app.$auth) {
		ctx.app.$auth.$storage.watchState('loggedIn', async loggedIn => {
			options.auth.headers = {
				...options.auth.headers,
				...getHeaders(ctx)
			}
		})
	}

	ctx.$echo = echo
	inject('echo', echo)
}
