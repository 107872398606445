<template>
	<b-modal
		id="activation-modal"
		v-model="isVisible"
		hide-footer
		title="Активация"
	>
		<p>
			{{ $t('activationMessage') }}
			{{ $t('sendLetterTo') }} {{ $auth.user.email }}
		</p>
		<nuxt-link
			v-if="$auth.user.is_corporate_client"
			class="btn btn-primary"
			to="/corporate-user/profile/"
			@click.native="isVisible = false"
		>
			{{ $t('goToLK') }}
		</nuxt-link>
		<nuxt-link
			v-else
			class="btn btn-primary"
			to="/user/profile/"
			@click.native="isVisible = false"
		>
			{{ $t('goToLK') }}
		</nuxt-link>
	</b-modal>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
	computed: {
		isVisible: {
			get() {
				return this.$store.state.activation.isVisible;
			},
			set(visible) {
				this.setIsVisible(visible);
			},
		},
	},
	watch: {
		'$auth.user.activated': {
			handler(activated) {
				this.isVisible = activated === false;
			},
			immediate: true,
		},
	},
	methods: {
		...mapMutations('activation', ['setIsVisible']),
	},
};
</script>
