<template>
	<div id="StickyMenuPopup" class="top_menu sub" :class="{ active: isSubMenuShow }">
		<div class="container">
			<div class="row mt-3 px-3 px-md-0 d-flex flex-column align-items-center">
				<span class="text-center px-5">Пройдите исследование о взаимодействии сообщества выпускников со Школой</span>
				<button class="btn btn-secondary mt-2" @click="showModal">Принять участие</button>
				<div class="d-flex align-items-center h-100 close-button">
					<button type="button" class="btn btn-close icon-close rounded-0 btn-primary" @click="closePopup"></button>
				</div>
			</div>
			<button
				v-if="!isSubMenuShow"
				v-b-tooltip.hover.bottom
				class="btn icon-chevron"
				title="Пройдите исследование о взаимодействии сообщества выпускников со Школой"
				:class="{ show: modalWasShown && anchor }"
				@click="isSubMenuShow = true; iconChevronHover = false"
				@mouseover="iconChevronHover = true"
				@mouseleave="iconChevronHover = false"
			/>
		</div>
		<alumni-research-modal :show="isModalShow" @hide="isModalShow = false" />
	</div>
</template>

<script>

import AlumniResearchModal from '~/components/ResearchAlumniModal.vue';

export default {
	name: 'StickyMenuPopup',
	components: { AlumniResearchModal },
	props: {
		topMenuHover: {
			type: Boolean,
			default: false,
		},
		fetchedData: { type: Object, default: () => ({}) },
	},
	data() {
		return {
			subMenuActive: false,
			isSubMenuShow: false,
			isModalShow: false,
			modalShown: false,
			iconChevronHover: false,
			modalInStorage: localStorage.getItem(`modal-alumni-research-show-${this.$auth?.user?.id}`),
			anchor: null,
		};
	},
	computed: {
		modalWasShown() {
			return !!this.modalInStorage || this.modalShown;
		},
		isShowIconChevron() {
			return this.modalWasShown ? this.topMenuHover || this.iconChevronHover : false;
		},
	},
	mounted() {
		const anchorPanels = this.fetchedData.blocks.filter((block) => block.type === 'anchor-panel');
		this.anchor = anchorPanels[0]?.content?.anchor;

		if (!this.modalWasShown) {
			this.$nextTick(() => {
				this.getAnchorForTrigger();
			});
		}
	},
	beforeDestroy() {
		document.removeEventListener('scroll', this.onScrollHandler);
	},
	methods: {
		onScrollHandler(targetElement) {
			if (this.isElementAtTop(targetElement) && !this.isSubMenuShow && !this.modalWasShown) {
				this.isSubMenuShow = true;
			}
		},
		isElementAtTop(element) {
			const rect = element.getBoundingClientRect();
			return rect.top <= 100;
		},
		showModal() {
			this.modalShown = true;
			this.isModalShow = true;
			this.isSubMenuShow = false;
		},
		closePopup() {
			this.modalShown = true;
			this.isSubMenuShow = false;
			localStorage.setItem(`modal-alumni-research-show-${this.$auth?.user?.id}`, 'true');
		},
		setWatcherForAnchor(id) {
			const targetElement = document.getElementById(id);

			if (targetElement) {
				window.addEventListener('scroll', this.onScrollHandler.bind(this, targetElement));
			}
		},

		getAnchorForTrigger() {
			setTimeout(() => {
				this.setWatcherForAnchor(this.anchor);
			}, 0);
		},
	},
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/components/layouts/top-menu-new";
</style>
