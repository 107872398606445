import {
	FIRST_NAME_FIELD_SLUG,
	LAST_NAME_FIELD_SLUG,
	SECOND_NAME_FIELD_SLUG,
	PHONE_NUMBER_FIELD_SLUG,
	EMAIL_FIELD_SLUG,
	CITY_FIELD_SLUG,
	COMPANY_FIELD_SLUG,
	JOB_FIELD_SLUG,
} from './commonFieldsSlugs';

export const DEFAULT_FIELDS_TO_PROFILE_STEP_2 = {
	[FIRST_NAME_FIELD_SLUG]: 'first_name',
	[LAST_NAME_FIELD_SLUG]: 'last_name',
	[SECOND_NAME_FIELD_SLUG]: 'middle_name',
	[PHONE_NUMBER_FIELD_SLUG]: 'phone',
	[EMAIL_FIELD_SLUG]: 'email',
	[CITY_FIELD_SLUG]: 'profile.location',
	[COMPANY_FIELD_SLUG]: 'profile.company_name',
	[JOB_FIELD_SLUG]: 'profile.job_title',
};

export const DEFAULT_FIELDS_TO_PROFILE_STEP_3 = {
};
