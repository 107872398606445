import { EVENT_FORM_SCENARIOS, SCENARIOS, TYPOLOGY } from '~/utils/dictionaries';

export default function getTargetAction(eventFormScenario, scenario, typology) {
	if (eventFormScenario === EVENT_FORM_SCENARIOS.REQUEST_PRESENTATION) {
		return 'Скачать документ';
	}
	if (eventFormScenario === EVENT_FORM_SCENARIOS.REQUEST_SUBSCRIPTION) {
		return 'Подписка на новости';
	}
	if (eventFormScenario === EVENT_FORM_SCENARIOS.REQUEST_STREAM_ACCESS) {
		return 'Запрос записи мероприятия';
	}
	if (eventFormScenario === EVENT_FORM_SCENARIOS.REGISTRATION) {
		if (scenario === SCENARIOS.BUY_PROGRAM) {
			return 'Оплата';
		}
		if (scenario === SCENARIOS.DONATION) {
			return 'Пожертвование';
		}
		if (scenario === SCENARIOS.REGISTRATION) {
			return 'Регистрация на программу/мероприятие';
		}
	}
	if ([EVENT_FORM_SCENARIOS.REQUEST_CONSULTATION,
		EVENT_FORM_SCENARIOS.REQUEST_CALL_BACK,
		EVENT_FORM_SCENARIOS.REQUEST_INVOICE,
		EVENT_FORM_SCENARIOS.REQUEST_CUSTOM].includes(eventFormScenario)) {
		return 'Заявка на консультацию';
	}

	return '';
}
