<template>
	<div class="container">
		<div class="menu_panel row">
			<div class="left_part col-7 col-sm-6 col-md-5 col-lg-3 col-xl-2">
				<nuxt-link :to="'/'" class="logo">
					<img src="/frontimg/skolkovo_logo.png" alt="Skolkovo">
				</nuxt-link>
			</div>
			<div class="middle_part col">
				<div class="top_menu d-none d-lg-block">
					<ul v-if="$i18n.locale ==='en'" class="menu">
						<li>
							<nuxt-link to="/en/about-school/">
								{{ $t('about-school') }}
							</nuxt-link>
						</li>
						<li>
							<a href="https://www.skolkovo.ru/en/programmes/">
								{{ $t('education') }}
							</a>
						</li>
						<!--<li>
							<nuxt-link :to="localePath({ name: 'article-section' })">
								inTrend
							</nuxt-link>
						</li>-->
						<!--<li>
							<nuxt-link to="/navigator/events/">
								{{ $t('events') }}
							</nuxt-link>
						</li>-->
						<li>
							<nuxt-link to="/en/academic/">
								{{ $t('academic') }}
							</nuxt-link>
						</li>
						<li>
							<nuxt-link to="/en/campus-rental/">
								{{ $t('campus') }}
							</nuxt-link>
						</li>
						<li>
							<nuxt-link to="/en/about-school/contacts/">
								{{ $t('contacts') }}
							</nuxt-link>
						</li>
					</ul>

					<ul v-else class="menu">
						<!--<li>
							<nuxt-link :to="localePath({ name: 'anti-fragility' })">
								{{ $t('anti-fragility') }}
							</nuxt-link>
						</li>-->
						<li>
							<nuxt-link :to="localePath({ name: 'about-school' })">
								{{ $t('about-school') }}
							</nuxt-link>
						</li>
						<li>
							<b-button
								id="panel-education-1"
								href="/navigator/programmes/?typologies=1"
								variant="link"
								class="p-0"
								tabindex="0"
							>
								{{ $t('education') }}
							</b-button>
							<b-popover
								target="panel-education-1"
								triggers="hover"
								placement="top"
								custom-class="top-menu-popover"
							>
								<div class="title title-lg pl-3">
									Список программ
								</div>
								<div class="d-flex">
									<div class="col-12">
										<div class="row">
											<div class="col-12 col-lg-4">
												<div class="head-block bt-md-0">
													<div class="title mb-3 d-none d-md-block">
														Международные стандарты MBA
													</div>
												</div>
											</div>
											<div class="col-12 col-lg-8 d-md-block">
												<div class="icons-block">
													<ul class="row">
														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Executive MBA
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/skolkovo-executive-mba/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>
														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	СКОЛКОВО MBA
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/skolkovo-mba/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>
														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	HKUST-SKOLKOVO EMBA for Eurasia
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="https://embahs.skolkovo.ru/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>
														<!-- <li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Master in Management Analytics | SKOLKOVO MMA
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/master-in-management-analytics/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li> -->
													</ul>
												</div>
											</div>
											<div class="col-12 col-lg-4">
												<div class="head-block bt-md-0">
													<div class="title mb-3 d-none d-md-block">
														Программы для малого и среднего бизнеса
													</div>
												</div>
											</div>
											<div class="col-12 col-lg-8 d-md-block">
												<div class="icons-block">
													<ul class="row">
														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Практикум
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/26102020-praktikum/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>
														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Стартап Академия
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/startap-akademiya-26/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>
														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Практикум Global Shift
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/22022021-practicum-global-shift/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>
														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	СКОЛКОВО Практикум Штаб
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/28092020-skolkovo-praktikum-shtab/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>

														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Венчурные инвестиции в стартапы
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/02042021venchurnye-investicii-v-startapy/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>

														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Тандемократия: партнерство для бизнеса и жизни
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/16062021-tandemokratiya/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>

														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Финансовая стратегия LIVE
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/03082020-finansovaya-strategiya-live/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>

														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Практикум по игротехнике LIVE
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/praktikum-po-igrotehnike-live/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>

														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Практикум по финансовой стратегии
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/03122020-finansovaya-strategiya-oflajn/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>

														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Global Shift Expedition. Turkey
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																href="/programmes/global-shift-expedition-turkey/">
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>
													</ul>
												</div>
											</div>
											<div class="col-12 col-lg-4">
												<div class="head-block bt-md-0">
													<div class="title mb-3 d-none d-md-block">
														Программы для cтарта карьеры
													</div>
												</div>
											</div>
											<div class="col-12 col-lg-8 d-md-block">
												<div class="icons-block">
													<ul class="row">
														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	MOOVE programme by SKOLKOVO x MTS
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/07112020-moove-by-skolkovo-mts/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>
														<!-- <li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Магистратура «Цифровые технологии в бизнесе»
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/05092020-magistratura-cifrovye-tehnologii-v-biznese-ot-mfti-biznes-shkoly-skolkovo-i-mts/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li> -->
														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Стартап Академия Junior
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/startup-academy-junior/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>
														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Карьерный BOOST: твой активный старт в профессиональном мире
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/07062021-karernyj-boost-tvoj-aktivnyj-start-v-professionalnom-mire/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>
													</ul>
												</div>
											</div>
											<div class="col-12 col-lg-4">
												<div class="head-block bt-md-0">
													<div class="title mb-3 d-none d-md-block">
														Открытые программы
													</div>
												</div>
											</div>
											<div class="col-12 col-lg-8 d-md-block">
												<div class="icons-block">
													<ul class="row">
														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Программы по направлениям
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/open-programs/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>
													</ul>
												</div>
											</div>
											<div class="col-12 col-lg-4">
												<div class="head-block bt-md-0">
													<div class="title mb-3 d-none d-md-block">
														Онлайн образование
													</div>
												</div>
											</div>
											<div class="col-12 col-lg-8 d-md-block">
												<div class="icons-block">
													<ul class="row">
														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Онлайн-программы
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/navigator/programmes/?typologies=1&project_locations=3"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>
													</ul>
												</div>
											</div>
											<div class="col-12 col-lg-4">
												<div class="head-block bt-md-0">
													<div class="title mb-3 d-none d-md-block">
														Центр Коучинга
													</div>
												</div>
											</div>
											<div class="col-12 col-lg-8 d-md-block">
												<div class="icons-block">
													<ul class="row">
														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Командный коучинг
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/centres/coaching/team-coaching/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>
														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Индивидуальный коучинг
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/centres/coaching/personal-coaching/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>
													</ul>
												</div>
											</div>
											<div class="col-12 col-lg-4">
												<div class="head-block bt-md-0">
													<div class="title mb-3 d-none d-md-block">
														Корпоративные решения
													</div>
												</div>
											</div>
											<div class="col-12 col-lg-8 d-md-block">
												<div class="icons-block">
													<ul class="row">
														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Решение для компаний
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/corporate/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>
													</ul>
												</div>
											</div>
											<div class="col-12 col-lg-4">
												<div class="head-block bt-md-0">
													<div class="title mb-3 d-none d-md-block">
														Государственные программы
													</div>
												</div>
											</div>
											<div class="col-12 col-lg-8 d-md-block">
												<div class="icons-block">
													<ul class="row">
														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Master of Public Administration
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/mpa/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>

														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Master in Public Strategy
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/mps/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>
														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Результативные коммуникации в госуправлении
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/programmes/05122021-rezultativnye-kommunikacii-v-gosupravlenii/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>
													</ul>
												</div>
											</div>
											<div class="col-12 col-lg-4">
												<div class="head-block bt-md-0">
													<div class="title mb-3 d-none d-md-block">
														Digital-программы
													</div>
												</div>
											</div>
											<div class="col-12 col-lg-8 d-md-block">
												<div class="icons-block">
													<ul class="row">
														<li class="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-between">
															<div class="item text-left mt-3 mb-4">
																<div class="title">
																	Digital-программы
																</div>
															</div>
															<div class="align-items-end mb-3">
																<a
																	href="/digital/"
																	class="more_link"
																>
																	Подробнее
																</a>
															</div>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</b-popover>
						</li>
						<li>
							<nuxt-link to="/intrend/">
								inTrend
							</nuxt-link>
						</li>
						<!-- <li>
							<nuxt-link to="/navigator/events/">
								{{ $t('events') }}
							</nuxt-link>
						</li> -->
						<li>
							<nuxt-link :to="localePath({ name: 'academic' })">
								{{ $t('academic') }}
							</nuxt-link>
						</li>
						<li>
							<nuxt-link :to="localePath({ name: 'campus' })">
								{{ $t('campus') }}
							</nuxt-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<b-sidebar
			id="sidebar-main"
			title=""
			bg-variant="dark"
			text-variant="light"
			shadow
		>
			<ul v-if="$i18n.locale ==='en'" class="list-unstyled sidebar-menu">
				<li>
					<nuxt-link to="/en/about-school/">
						{{ $t('about-school') }}
					</nuxt-link>
				</li>
				<li>
					<a href="https://www.skolkovo.ru/en/programmes/">
						{{ $t('education') }}
					</a>
				</li>
				<!--<li>
					<nuxt-link :to="localePath({ name: 'article-section' })">
						inTrend
					</nuxt-link>
				</li>-->
				<!--<li>
					<nuxt-link to="/navigator/events/">
						{{ $t('events') }}
					</nuxt-link>
				</li>-->
				<li>
					<nuxt-link to="/en/academic/">
						{{ $t('academic') }}
					</nuxt-link>
				</li>
				<li>
					<nuxt-link to="/en/campus-rental/">
						{{ $t('campus') }}
					</nuxt-link>
				</li>
				<li>
					<nuxt-link to="/en/about-school/contacts/">
						{{ $t('contacts') }}
					</nuxt-link>
				</li>
			</ul>

			<ul v-else class="list-unstyled sidebar-menu">
				<!--<li>
					<nuxt-link :to="localePath({ name: 'anti-fragility' })">
						{{ $t('anti-fragility') }}
					</nuxt-link>
				</li>-->
				<li>
					<nuxt-link :to="localePath({ name: 'about-school' })">
						{{ $t('about-school') }}
					</nuxt-link>
				</li>
				<li>
					<nuxt-link to="/navigator/programmes/?typologies=1">
						{{ $t('education') }}
					</nuxt-link>
				</li>
				<!-- <li>
					<nuxt-link to="/navigator/events/">
						{{ $t('events') }}
					</nuxt-link>
				</li> -->
				<li>
					<nuxt-link :to="localePath({ name: 'academic' })">
						{{ $t('academic') }}
					</nuxt-link>
				</li>
				<li>
					<nuxt-link to="/intrend/">
						inTrend
					</nuxt-link>
				</li>
				<li>
					<nuxt-link :to="localePath({ name: 'campus' })">
						{{ $t('campus') }}
					</nuxt-link>
				</li>
				<li>
					<nuxt-link
						:to="localePath({ name: 'about-school-inner-page',
							params: { slug: 'contacts' } })"
					>
						{{ $t('contacts') }}
					</nuxt-link>
				</li>
			</ul>
			<div class="border-top my-4" />

			<ul v-if="$i18n.locale ==='en'" class="list-unstyled sidebar-menu">
				<li><a href="https://canvas.skolkovo.ru/login/canvas">{{ $t('students') }}</a></li>
				<!--<li><a href="https://alumni.skolkovo.ru/">{{ $t('alumni') }}</a></li>-->
				<li><a href="https://sk.skolkovo.ru/login">{{ $t('staff') }}</a></li>
			</ul>

			<ul v-else class="list-unstyled sidebar-menu">
				<li><a href="https://canvas.skolkovo.ru/login/canvas">{{ $t('students') }}</a></li>
				<li><a href="https://alumni.skolkovo.ru/">{{ $t('alumni') }}</a></li>
				<li><a href="https://sk.skolkovo.ru/login">{{ $t('staff') }}</a></li>
			</ul>
		</b-sidebar>
	</div>
</template>

<script>
export default {
	name: 'IssueMenu',
};
</script>

<style lang="scss" scoped>
	@import "assets/styles/sk_variables";
	// For use bootstrap vars and mixins inside
	@import "assets/styles/bootstrap/scss/functions";
	@import "assets/styles/bootstrap/scss/variables";
	@import "assets/styles/bootstrap/scss/mixins";
	@import "~/assets/styles/components/layouts/issue-menu";
	@import "~/assets/styles/components/home/panel-popover";
	.menu .btn {
		line-height:1;
		vertical-align: top;
		border:0;
	}
</style>

<i18n lang="yaml">
ru:
  anti-fragility: "Антихрупкость"
  about-school: "О школе"
  education: "Образование"
  events: "События"
  campus: "Кампус"
  academic: "Академический блок"
  alumni: "Выпускники"
  staff: "Сотрудники"
  students: "Студенты"
  contacts: "Контакты"
en:
  anti-fragility: "Antifragility"
  about-school: "About the School"
  education: "Education"
  events: "Events"
  campus: "Campus"
  academic: "Academic Division"
  alumni: "Alumni"
  staff: "Staff"
  students: "Students"
  contacts: "Contacts"
</i18n>
