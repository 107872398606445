export function state() {
	return {
		noPassport: null,
		isPassportFilled: false,
		isSnilsFilled: false,
		isEducationsFilled: false,
		valuesEducationsState: {},
		valuesSnilsState: {},
		valuesPassportState: {},
	};
}

export const mutations = {
	setPassportType(state, type) {
		state.noPassport = type;
	},
	setIsFilledPassport(state, value) {
		state.isPassportFilled = value;
	},
	setIsFilledSnils(state, value) {
		state.isSnilsFilled = value;
	},
	setIsFilledEducations(state, value) {
		state.isEducationsFilled = value;
	},
	setEducations(state, value) {
		state.valuesEducationsState = value;
	},
	setPassport(state, value) {
		state.valuesPassportState = value;
	},
	setSnils(state, value) {
		state.valuesSnilsState = value;
	},
};

export const actions = {
	async setEducationsAction({ commit }, data) {
		commit('setEducations', data);
	},
	async setPassportAction({ commit }, data) {
		commit('setPassport', data);
	},
	async setSnilsAction({ commit }, data) {
		commit('setSnils', data);
	},
};
