import { ACTION_HANDLE_UNIQUE_ID, UNIQUE_ID_STORE_NAMESPACE, ACTION_SET_NULL_UNIQUE_ID } from '@/store/uniqueId';

// @vue/component
export default {
	computed: {
		uuid() {
			return this.$store.state[UNIQUE_ID_STORE_NAMESPACE].uniqueId;
		},
	},
	methods: {
		async reFillUuid() {
			const { $cookies } = this;
			await this.$store.dispatch(`${UNIQUE_ID_STORE_NAMESPACE}/${ACTION_SET_NULL_UNIQUE_ID}`, { $cookies });

			await this.fillUuid();
		},
		async fillUuid() {
			if (!this.$auth.loggedIn) {
				const { $cookies } = this;
				const uuid = await this.$store.dispatch(`${UNIQUE_ID_STORE_NAMESPACE}/${ACTION_HANDLE_UNIQUE_ID}`, { $cookies });

				const setUuidToDefaults = (axios) => {
					if (!axios.defaults.params) {
						// eslint-disable-next-line no-param-reassign
						axios.defaults.params = {};
					}
					// eslint-disable-next-line no-param-reassign
					axios.defaults.params.uuid = uuid;
					// eslint-disable-next-line no-param-reassign
					axios.defaults.params.skuid = uuid;
				};
				setUuidToDefaults(this.$api.axios);
				setUuidToDefaults(this.$pagesApi.axios);

				return uuid;
			}

			return this.uuid;
		},
	},
};
