<template>
	<b-modal id="search-modal" hide-footer hide-header centered size="md">
		<template #default="{ close }">
			<b-overlay
				:show="showRecognitionOverlay"
				rounded="sm"
				variant="transparent"
				@shown="onRecognitionShown"
				@hidden="onRecognitionHidden"
			>
				<template #overlay>
					<intersect @enter.once="onVisible">
						<div class="text-center">
							<b-icon-mic-fill class="text-danger" font-scale="3" animation="cylon" />
							<p id="cancel-label" class="text-light">
								{{ $t('pleaseSpeak') }}
							</p>
							<b-button
								ref="cancel"
								variant="outline-danger"
								size="sm"
								aria-describedby="cancel-label"
								@click="showRecognitionOverlay = false"
							>
								{{ $t('cancel') }}
							</b-button>
						</div>
					</intersect>
				</template>
				<div
					class="vue-portal-target"
					:aria-hidden="showRecognitionOverlay ? 'true' : null"
				>
					<div class="modal__inner">
						<div class="modal__cnt">
							<div class="search">
								<div class="search__title mb-5">
									{{ $t('siteSearch') }}
								</div>
								<b-button class="icon-close modal__close" @click="close()" />
								<b-form action="/search/">
									<b-form-group>
										<b-input-group class="mt-3 mb-5">
											<b-form-input
												v-model="search"
												autofocus
												type="text"
												class="search__field"
												name="q"
												:placeholder="$t('example')"
											/>
											<b-input-group-append v-if="browser">
												<speech-button
													ref="showRecognition"
													v-model="search"
													@start="showRecognitionOverlay = true"
													@end="showRecognitionOverlay = false"
												/>
											</b-input-group-append>
										</b-input-group>

										<b-button size="lg" type="submit" variant="primary" class="mb-0">
											{{ $t('search') }}
											<b-icon-search />
										</b-button>
									</b-form-group>
								</b-form>
							</div>
						</div>
					</div>
				</div>
			</b-overlay>
		</template>
	</b-modal>
</template>

<script>
import Intersect from 'vue-intersect';
import {
	BIconSearch, BIconMicFill, BOverlay, BInputGroup,
} from 'bootstrap-vue';
import SpeechButton from '~/components/layouts/_partials/SpeechButton.vue';

export default {
	name: 'SearchModal',
	components: {
		Intersect,
		BIconSearch,
		BInputGroup,
		SpeechButton,
		BIconMicFill,
		BOverlay,
	},
	data() {
		return {
			browser: true,
			search: '',
			showRecognitionOverlay: false,
		};
	},
	methods: {
		onVisible() {
			this.detectBrowser();
		},
		onRecognitionShown() {
			this.$refs.cancel.focus();
		},
		onRecognitionHidden() {
			if (this.$refs.showRecognition) {
				this.$refs.showRecognition.abort();
				this.$refs.showRecognition.focus();
			}
		},
		detectBrowser() {
			const { userAgent } = navigator;
			if (userAgent.match(/safari/i)) {
				this.browser = false;
			}
			if (userAgent.match(/opr\//i)) {
				this.browser = false;
			}
			if (userAgent.match(/edg/i)) {
				this.browser = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/dev/modal-search";
</style>
