<template>
	<div ref="wrapper">
		<b-modal
			id="modal-alumni-research"
			v-model="isShowModal"
			centered
			hide-footer
			dialog-class="custom-dialog"
			:content-class="{'is-loading' : isLoading}"
			size="xl"
			@hide="onModalHide"
			@show="onModalShow"
		>
			<div v-if="isLoading" class="d-flex align-items-center h-100 custom-loader">
				<div class="mx-auto">
					<b-spinner class="spinner" variant="primary" />
				</div>
			</div>
		</b-modal>
		<div v-show="isShowModal && !isLoading" id="anketolog-frame-798379"></div>
	</div>
</template>

<script>
const FRAME_FOR_LOADING_CONTENT = '#anketolog-frame-798379';
const MODAL_BODY = '#modal-alumni-research___BV_modal_body_';
const UPDATED_IFRAME = 'iframe[src="https://anketolog.ru/api/v2/frame/survey/798379/GP983LFW?"]';

export default {
	name: 'AlumniResearchModal',
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isShowModal: false,
			isLoading: true,
		};
	},
	computed: {
		modalShown() {
			return !!localStorage.getItem(`modal-alumni-research-show-${this.$auth?.user?.id}`);
		},
	},
	watch: {
		show(value) {
			if (value) {
				this.showModal();
			}
		},
	},
	mounted() {
		this.initializePluginScript();
	},
	methods: {
		showModal() {
			this.isShowModal = true;
			this.$nextTick(() => {
				this.hideLoading();
			});
		},
		initializePluginScript() {
			const d = document;
			const u = 'https://anketolog.ru/api/v2/frame/js/798379?token=GP983LFW';
			const s = d.createElement('script');
			s.type = 'text/javascript';
			s.async = true;
			s.src = u;
			d.body.appendChild(s);

			this.$nextTick(() => {
				this.hideLoading();
			});
		},
		hideLoading() {
			const targetElement = document.querySelector(FRAME_FOR_LOADING_CONTENT);
			const modal = document.querySelector(MODAL_BODY);
			if (modal) {
				modal.appendChild(targetElement);

				this.$nextTick(() => {
					const iframe = document.querySelector(UPDATED_IFRAME);

					iframe.addEventListener('load', () => {
						this.isLoading = false;
					}, { once: true });
				});
			}
		},
		onModalShow() {
			localStorage.setItem(`modal-alumni-research-show-${this.$auth?.user?.id}`, 'true');
		},
		onModalHide() {
			this.$emit('hide');
			// Перенос фрейма в контейнер для возможности повторного отображения формы без перезагрузки страницы
			const targetElement = document.querySelector('#anketolog-frame-798379');
			this.$refs.wrapper.appendChild(targetElement);
		},
	},
};
</script>
<style lang="scss">
.modal-dialog.modal-xl.modal-dialog-centered.custom-dialog {
	max-width: 95vw;
}

.is-loading {
	left: -1000rem !important;
}

.custom-loader {
	position: relative;
	right: -1000rem;
}
</style>
