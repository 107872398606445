/* eslint-disable no-param-reassign */

import Vue from 'vue';

Vue.use({
	// eslint-disable-next-line no-shadow
	install(Vue) {
		const locale = 'Ru-ru';
		// const locale = undefined;

		const dateFormat = new Intl.DateTimeFormat(locale);
		const dateTimeFormat = new Intl.DateTimeFormat(locale, {
			dateStyle: 'short',
			timeStyle: 'short',
		});

		Vue.prototype.$date = (text) => dateFormat.format(new Date(text));
		Vue.prototype.$datetime = (text) => dateTimeFormat.format(new Date(text));
	},
});
