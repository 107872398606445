<template>
	<div class="nuxt-error">
		<component :is="errorPage" :error="error" />
	</div>
</template>

<script>
import { STATUSES } from '~/utils/dictionaries';

import error401 from '~/layouts/401.vue';
import error404 from '~/layouts/404.vue';
import error500 from '~/layouts/500.vue';
import maintenance from '~/layouts/maintenance.vue';

export default {
	name: 'NuxtError',
	layout: 'default',
	props: {
		error: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		errorPage() {
			if (this.error.statusCode === STATUSES.UNAUTHORIZED) {
				return error401;
			}
			if (this.error.statusCode === STATUSES.NOT_FOUND) {
				return error404;
			}
			if (this.error.statusCode === STATUSES.SERVICE_UNAVAILABLE) {
				return maintenance;
			}
			// catch everything else
			return error500;
		},
	},
};
</script>
