export function state() {
	return {
		roles: null,
		userProfile: {},
		formulateUserProfile: null,
	};
}

export const mutations = {
	setRoles(state, roles) {
		state.roles = roles;
	},
	setProfile(state, profile) {
		state.userProfile = profile;
	},
	setFieldOfProfile(state, obj) {
		state.userProfile[obj.slug] = obj.value;
	},
	setFormulateUserProfile(state, profile) {
		state.formulateUserProfile = profile;
	},
};

export const getters = {
	isAlumni: (state) => {
		if (state.roles) {
			return state.roles.includes('alumni');
		}

		return false;
	},
};

let getRolesPromise = null;
let getUserProfilePromise = null;
let getUserFormulateProfilePromise = null;

export const actions = {
	async getRoles({ state, commit }) {
		if (!this.$auth.loggedIn || state.roles) {
			return state.roles;
		}

		if (!getRolesPromise) {
			getRolesPromise = this.$api.axios.$get('/api/v2/user/roles');
			const roles = await getRolesPromise;

			commit('setRoles', roles);

			return roles;
		}

		return getRolesPromise;
	},
	async getProfile({ state, commit }) {
		// if (!this.$auth.loggedIn || state.userProfile) {
		// 	return state.userProfile;
		// }

		getUserProfilePromise = this.$api.getUserProfile();
		const profile = await getUserProfilePromise;

		commit('setProfile', profile);

		return profile;


		// if (!getUserProfilePromise) {
		//
		// }

		return getUserProfilePromise;
	},
	async getFormulateProfile({ state, commit }) {
		if (!getUserFormulateProfilePromise) {
			getUserFormulateProfilePromise = this.$api.getFormulateProfile();
		}

		if (this.$auth.loggedIn
			&& !state.formulateUserProfile
		) {
			const profile = await getUserFormulateProfilePromise;

			commit('setFormulateUserProfile', profile);

			return profile;
		}

		return state.formulateUserProfile;
	},
};
