export default (href) => new Promise((resolve, reject) => {
	const head = document.getElementsByTagName('head')[0];
	const link = document.createElement('link');

	link.rel = 'stylesheet';
	link.type = 'text/css';
	link.media = 'all';

	link.onload = resolve;
	link.onerror = reject;
	link.onreadystatechange = function onReadyStateChange() {
		if (this.readyState === 'complete' || this.readyState === 'loaded') {
			setTimeout(this.onload, 0);
		}
	};

	link.href = href;

	head.appendChild(link);
});
