export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: true,
}
export const options = {
  vueI18n: (context) => import('~/config/vue-i18n.js').then(m => m.default(context)),
  vueI18nLoader: true,
  locales: [{"name":"English","code":"en","iso":"en-US","file":"en-US.js"},{"name":"Русский","code":"ru","iso":"ru-RU","file":"ru-RU.js"}],
  defaultLocale: "ru",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":false,"syncLocale":true,"syncMessages":false},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"name":"English","code":"en","iso":"en-US","file":"en-US.js"},{"name":"Русский","code":"ru","iso":"ru-RU","file":"ru-RU.js"}],
  localeCodes: ["en","ru"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "-",
  5: "U",
  6: "S",
  7: ".",
  8: "j",
  9: "s",
  10: "\"",
  11: ":",
  12: "\"",
  13: ".",
  14: ".",
  15: "/",
  16: "l",
  17: "a",
  18: "n",
  19: "g",
  20: "/",
  21: "e",
  22: "n",
  23: "-",
  24: "U",
  25: "S",
  26: ".",
  27: "j",
  28: "s",
  29: "\"",
  30: ",",
  31: "\"",
  32: "r",
  33: "u",
  34: "-",
  35: "R",
  36: "U",
  37: ".",
  38: "j",
  39: "s",
  40: "\"",
  41: ":",
  42: "\"",
  43: ".",
  44: ".",
  45: "/",
  46: "l",
  47: "a",
  48: "n",
  49: "g",
  50: "/",
  51: "r",
  52: "u",
  53: "-",
  54: "R",
  55: "U",
  56: ".",
  57: "j",
  58: "s",
  59: "\"",
  60: "}",
}

export const localeMessages = {
  'en-US.js': () => import('../../lang/en-US.js' /* webpackChunkName: "lang-en-US.js" */),
  'ru-RU.js': () => import('../../lang/ru-RU.js' /* webpackChunkName: "lang-ru-RU.js" */),
}
