<!--Главная Антихрупкости-->
<template>
	<div>
		<header class="header">
			<div
				class="header_wrapper"
				:style="'background-image:url(//sk.skolkovo.ru/img/d800f499-1e79-4f5b-bd7c-ec7c94b77113/mask-dollar.jpg?fm=jpg&q=80&fit=crop&crop=2496%2C1067%2C0%2C0&w=2560&h=948);'"
			>
				<top-menu-new/>
				<div class="container mt-5 pt-lg-5">
					<div class="header_main">
						<div class="title_block">
							<h1>401</h1>
							<div class="subtitle">
								Неавторизовано
							</div>
							<p>
								{{ message }}
							</p>
							<nuxt-link :to="{ name: 'index' }" class="btn btn-primary">
								На главную
							</nuxt-link>
						</div>
					</div>
				</div>
			</div>
		</header>
		<main-footer />
	</div>
</template>

<script>
import MainFooter from '~/components/layouts/footers/MainFooter.vue';
import IssueMenu from '~/components/layouts/_partials/IssueMenu.vue';
import TopMenuNew from '~/components/layouts/_partials/TopMenuNew.vue';

export default {
	components: {
		MainFooter,
		IssueMenu,
		TopMenuNew,
	},
	props: { error: { type: Object, required: true } },
	computed: {
		message() {
			return this.error.message;
		},
	},
	head() {
		return {
			title: '401',
		};
	},
};
</script>

<style lang="scss" scoped>
	@import '~/assets/styles/components/layouts/issue-header-panel';
</style>
