export const FIRST_NAME_FIELD_SLUG = 'first_name';
export const LAST_NAME_FIELD_SLUG = 'last_name';
export const SECOND_NAME_FIELD_SLUG = 'second_name';
export const PHONE_NUMBER_FIELD_SLUG = 'phone';
export const EMAIL_FIELD_SLUG = 'email';
export const CITY_FIELD_SLUG = 'city';
export const COMPANY_FIELD_SLUG = 'company';
export const JOB_FIELD_SLUG = 'job';
export const LEGAL_NAME_FIELD_SLUG = 'legal_name';
export const LEGAL_ADDRESS_FIELD_SLUG = 'legal_address_value';
export const LEGAL_INN_FIELD_SLUG = 'legal_inn';
export const LEGAL_KPP_FIELD_SLUG = 'legal_kpp';
