const COOKIE_ACCEPTED_KEY = 'is_cookie_accepted';

function getCookieOptions() {
	const oneYearFromNow = new Date();
	oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
	return { expires: oneYearFromNow, path: '/' };
}

function initGTM() {
	(function (w, d, s, l, i) {
		w[l] = w[l] || [];
		w[l].push({
			'gtm.start': new Date().getTime(),
			event: 'gtm.js',
		});
		const f = d.getElementsByTagName(s)[0];
		const j = d.createElement(s);
		const dl = l != 'dataLayer' ? `&l=${l}` : '';
		j.async = true;
		j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
		f.parentNode.insertBefore(j, f);
	}(window, document, 'script', 'dataLayer', 'GTM-5FDN6KM'));
}

export function state() {
	return {
		isCookiesAccepted: undefined,
		isVisible: false,
		isRejectable: true,
	};
}

export const mutations = {
	show(state, rejectable = true) {
		state.isVisible = true;
		state.isRejectable = rejectable;
	},
	hide(state) {
		state.isVisible = false;
		state.isRejectable = false;
	},
	setVisible(state, visible) {
		state.isVisible = visible;
	},
	accept(state) {
		state.isCookiesAccepted = true;
	},
	reject(state) {
		state.isCookiesAccepted = false;
	},
	setAccepted(state, accepted) {
		state.isCookiesAccepted = accepted;
	},
};

export const actions = {
	async init({ commit }, $cookies) {
		const accepted = $cookies.get(COOKIE_ACCEPTED_KEY);
		commit('setAccepted', accepted);
		commit('setVisible', accepted === undefined);
	},
	async initScripts({ state }) {
		if (state.isCookiesAccepted !== false) {
			initGTM();
		}
	},
	async accept({ commit, dispatch }, $cookies) {
		commit('hide');
		commit('accept');

		$cookies.set(COOKIE_ACCEPTED_KEY, true, getCookieOptions());

		dispatch('initScripts');
	},
	async reject({ commit }, $cookies) {
		commit('hide');
		commit('reject');

		$cookies.set(COOKIE_ACCEPTED_KEY, false, getCookieOptions());
	},
	async show({ state, commit }, isRejectable) {
		if (!state.isVisible || state.isRejectable !== isRejectable) {
			commit('show', isRejectable);
		}
	},
	async hide({ commit }) {
		commit('hide');
	},
};
