<template>
	<b-button
		v-if="recognition"
		variant="primary"
		@click="start"
	>
		<b-icon-mic />
	</b-button>
</template>

<script>
import { BIconMic, BButton } from 'bootstrap-vue';

/**
 * @link https://developer.mozilla.org/en-US/docs/Web/API/SpeechRecognition
 */
export default {
	name: 'SpeechButton',
	components: {
		BIconMic,
		BButton,
	},
	props: {
		value: { type: String, required: true },
	},
	data() {
		return {
			recognition: null,
		};
	},
	mounted() {
		const Recognition = window.SpeechRecognition || window.webkitSpeechRecognition;
		if (!Recognition) return;

		this.recognition = new Recognition();
		this.recognition.continuous = false;
		// defaults to the HTML lang attribute value, or the user agent's language
		// this.recognition.lang = 'ru-RU';
		this.recognition.interimResults = false;
		this.recognition.maxAlternatives = 1;

		this.recognition.onresult = this.onRecognition;
		this.recognition.onstart = this.onStart;
		this.recognition.onend = this.onEnd;
		this.recognition.onaudiostart = this.onAudiostart;
		this.recognition.onaudioend = this.onAudioend;
		this.recognition.error = this.onError;
	},
	beforeDestroy() {
		this.abort();
	},
	methods: {
		start() {
			this.recognition.start();
		},
		abort() {
			if (this.recognition) {
				this.recognition.abort();
			}
		},
		stop() {
			this.recognition.stop();
		},
		onRecognition(event) {
			this.$emit('input', event.results[0][0].transcript);
		},
		onStart() {
			this.$emit('start');
		},
		onEnd() {
			this.$emit('end');
		},
		onAudiostart() {
			this.$emit('audiostart');
		},
		onAudioend() {
			this.$emit('audioend');
		},
		onError() {
			this.$emit('error');
		},
		focus() {
			this.$el.focus();
		},
	},
};
</script>
