import { render, staticRenderFns } from "./IssueMenu.vue?vue&type=template&id=4d8fe694&scoped=true"
import script from "./IssueMenu.vue?vue&type=script&lang=js"
export * from "./IssueMenu.vue?vue&type=script&lang=js"
import style0 from "./IssueMenu.vue?vue&type=style&index=0&id=4d8fe694&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d8fe694",
  null
  
)

/* custom blocks */
import block0 from "./IssueMenu.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BPopover, BSidebar} from 'bootstrap-vue'
    installComponents(component, {BButton, BPopover, BSidebar})
    

export default component.exports