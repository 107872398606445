<template>
	<div id="main_footer" class="panel footer theme-dark">
		<div :class="fluid ? 'container-fluid' : 'container'">
			<div class="border-bottom pb-4 mb-4">
				<div class="row align-items-end">
					<div class="col-9 col-sm-6 col-md-5 col-lg-2 mb-3 mb-sm-0">
						<a href="/" class="logo">
							<!-- <b-img-lazy src="/frontimg/logo.svg" alt="logo"> -->
							<b-img-lazy src="/frontimg/logo-skolkovo-eq-wh.png" alt="logo" />
						</a>
					</div>
					<div
						class="col-12 col-sm-5 col-md-5 col-lg-6 offset-sm-1 offset-md-2 offset-lg-1 contact_block"
					>
						<div class="row">
							<div class="col-12 col-lg-4 mt-2">
								<a href="tel:+74955393003" class="contact icon-phone">+7 495 539 30 03</a>
							</div>
							<div class="col-12 col-lg-4 mt-2">
								<a href="mailto:info@skolkovo.ru" class="contact icon-email">info@skolkovo.ru</a>
							</div>
							<div class="col-10 col-sm-12 col-md-8 col-lg-4 mt-2 pr-lg-0">
								<div class="share">
									<div class="share_list">
										<a
											href="//t.me/skolkovo_channel"
											target="_blank"
											aria-label="telegram"
										><span class="icon-telegram" /></a>
										<a
											href="//www.youtube.com/user/skolkovoschool"
											target="_blank"
											class="yt-icon"
											aria-label="YouTube"
										>
											<span class="icon-yt" />
										</a>
										<a
											href="//vk.com/skolkovoschool"
											target="_blank"
											aria-label="vk"
										><span class="icon-vk" /></a>
										<a
											href="//zen.yandex.ru/id/5bfeb27acd7ace00a9d64b0d"
											target="_blank"
											class="zen-icon"
											aria-label="Yandex Zen"
										>
											<span class="icon-zen" />
										</a>
										<a
											href="//vc.ru/s/shkola-upravleniya-skolkovo-84321"
											target="_blank"
											aria-label="vc"
										><span class="icon-logo-vc-ru" /></a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-3 text-lg-right">
						<a v-if="$i18n.locale ==='en'" href="/en/donation/" class="btn btn-lg btn-primary">
							{{ $t('giveNow') }}
						</a>
						<a v-else href="/donation/" class="btn btn-lg btn-primary">
							{{ $t('giveNow') }}
						</a>
					</div>
				</div>
			</div>

			<!--
      <div class="border-bottom border-top mb-4">
        <div class="row pt-4 pb-4">

          <div class="col-12 col-lg-6">
            <div class="subscribe_block">
              <div class="title">Skolkovo BE in Trend</div>

              <form action="">
                <div class="form-row">
                  <div
                    class="form-group col-12 mb-0"
                  >
                    <div class="input-box">
                      <input
                        data-msg="Пожалуйста, укажите ваш email"
                        name="email"
                        type="email"
                        placeholder="Введите email*"
                        class="form-control rounded-0 required"
                      >
                      <div class="btn icon-mail"></div>
                    </div>
                    <label>
                      Подпишитесь на наши новости, чтобы получать анонсы новых программ и приглашения на мастер-классы
                    </label>
                    <label
                      class="error"
                    >Пожалуйста, укажите ваш email</label>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="col-12 col-lg-6">
            <div class="magazine_block">
              <div class="magazine_block-box">
                <div class="title">Skolkovo BE in Trend</div>
                <div class="description">
                  <p>Журнал для наших друзей. В онлайн версии журнала мы регулярно пишем о мировых тенденциях бизнес-образования и менеджмента</p>
                </div>
                <div class="image">
                  <b-img-lazy src="/frontimg/footer_magazine_cover.png" alt="magazine cover" />
                </div>
              </div>
              <div class="btn btn-secondary">Читать журнал</div>
            </div>

          </div>
        </div>
      </div>
      -->

			<div class="row">
				<div class="col-12 col-md-6 col-lg-3 mb-5">
					<div class="menu-box">
						<div>
							<div class="title">
								<nuxt-link to="/en/about-school/">
									{{ $t('about-school') }}
								</nuxt-link>
							</div>
							<ul class="list-unstyled footer-sub-menu">
								<!-- <li>
									<nuxt-link to="/en/about-school/management/">
										{{ $t('management') }}
									</nuxt-link>
								</li> -->
								<!--<li>
									<nuxt-link to="/en/about-school/accreditations-and-partnerships/">
										{{ $t('accreditations-and-partnerships') }}
									</nuxt-link>
								</li>-->
								<li>
									<nuxt-link to="/en/about-school/sustainability/">
										{{ $t('sustainability') }}
									</nuxt-link>
								</li>
								<li>
									<nuxt-link to="/en/about-school/career/">
										{{ $t('careers') }}
									</nuxt-link>
								</li>
								<li>
									<nuxt-link to="/en/about-school/contacts/">
										{{ $t('contacts') }}
									</nuxt-link>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-3 mb-5">
					<div class="menu-box">
						<ul class="list-unstyled footer-main-menu title">
							<li>
								<a href="https://www.skolkovo.ru/en/programmes/">
									{{ $t('education') }}
								</a>
							</li>
							<!--<li>
								<nuxt-link to="/en/academic/">
									{{ $t('academic') }}
								</nuxt-link>
							</li>-->
							<!--<li>
								<a target="_blank" href="https://alumni.skolkovo.ru/">{{ $t('alumni') }}</a>
							</li>-->
						</ul>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-3 mb-5">
					<div class="menu-box">
						<div>
							<div class="title">
								<nuxt-link to="/en/academic/">
									{{ $t('academic') }}
								</nuxt-link>
							</div>
							<ul class="list-unstyled footer-sub-menu">
								<li>
									<nuxt-link to="/en/academic/academic-centres/">
										{{ $t('academicCentres') }}
									</nuxt-link>
								</li>
								<li>
									<nuxt-link to="/en/academic/academic-faculty/">
										{{ $t('faculty') }}
									</nuxt-link>
								</li>
								<li>
									<nuxt-link to="/en/academic/academic-library/">
										{{ $t('library') }}
									</nuxt-link>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<!--<div class="col-12 col-md-6 col-lg-3 mb-5">
            <div class="menu-box prog">
              <div>
                <ul class="list-unstyled footer-main-menu title">
                  <li>
                    <a href="/navigator/events/">{{ $t('events') }}</a>
                  </li>
                  <li>
                    <a href="/media/">{{ $t('media') }}</a>
                  </li>
                </ul>
              </div>

            </div>
          </div>-->

				<div class="col-12 col-md-6 col-lg-3 mb-5">
					<div class="title">
						<nuxt-link to="/en/campus-rental/">
							{{ $t('campus') }}
						</nuxt-link>
					</div>
					<div class="menu-box events">
						<ul class="list-unstyled footer-sub-menu">
							<li>
								<nuxt-link to="/en/campus-rental/infrastructure/">
									{{ $t('infrastructure') }}
								</nuxt-link>
							</li>
							<li>
								<nuxt-link to="/en/campus-rental/accommodation/">
									{{ $t('accommodation') }}
								</nuxt-link>
							</li>
							<li>
								<nuxt-link to="/en/campus-rental/post-pandemic-safety/">
									{{ $t('pandemicSafety') }}
								</nuxt-link>
							</li>
							<li>
								<nuxt-link to="/en/campus-rental/booking/">
									{{ $t('booking') }}
								</nuxt-link>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<p class="text-muted">
						&copy; 2023 {{ $t('copyright') }}
						<a
							role="button"
							class="int-prop"
							@click="showModal()"
						>{{ $t('intellectual-property') }}</a>
					</p>
					<p class="small text-muted">
						The information on this website is for information and reference purposes and is not a public offer within the meaning of Article 437 of the Civil Code of the Russian Federation. For more detailed information you can contact the consultants of the SKOLKOVO School of Management.
					</p>
				</div>
			</div>
		</div>

		<ScrollTopComponent />

		<b-modal id="intellect-property">
			<template #modal-title>
				{{ $t('intellectual-property') }}
			</template>
			<div class="d-block text-center">
				<p>
					Бизнес-школа СКОЛКОВО уважает чужие права на объекты интеллектуальной собственности и
					принимает все необходимые меры, чтобы их не нарушать.
				</p>
				<p>
					В случае выявления случая нарушения Ваших интеллектуальных прав и иных противоправных действий
					других пользователей Веб-сайта, просим сообщить нам об этом на адрес
					<a
						href="mailto:abuse@skolkovo.ru"
					>abuse@skolkovo.ru</a>.
				</p>
			</div>
			<template #modal-footer="{ ok }">
				<b-button size="sm" variant="success" @click="ok()">
					OK
				</b-button>
			</template>
		</b-modal>

		<!--<b-button @click="$bvModal.show('search-modal')">Поиск!</b-button>-->
		<b-modal id="search-modal" hide-footer hide-header centered size="md">
			<template #default="{ close }">
				<div class="vue-portal-target">
					<div class="modal__inner">
						<!--<button type="button" class="modal__layer" /> -->
						<div class="modal__cnt">
							<div class="search">
								<div class="search__title mb-5">
									{{ $t('siteSearch') }}
								</div>
								<b-button class="icon-close" @click="close()" />
								<b-form action="/search/">
									<b-form-group>
										<b-form-input
											autofocus
											type="text"
											class="search__field mb-5"
											name="q"
											:placeholder="$t('example')"
										/>

										<b-button size="lg" type="submit" variant="primary" class="mb-0">
											{{ $t('search') }}
										</b-button>
									</b-form-group>
								</b-form>
							</div>
						</div>
					</div>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import '@/utils/moment';
import { mapActions } from 'vuex';
import ScrollTopComponent from '~/components/ScrollTopComponent.vue';

export default {
	name: 'MainFooterEng',
	components: {
		ScrollTopComponent,
	},
	props: {
		fetchedData: {
			type: Object,
			default: () => ({}),
		},
		fluid: { type: Boolean, default: false },
	},
	data() {
		return {
			programs: [],
			events: [],
			isTopButtonActive: false,
			modalIntellectPropertySrc: false,
		};
	},
	mounted() {
		this.$pagesApi
			.events({
				typology: 0,
				per_page: 4,
				index_list: true,
			})
			.then((response) => {
				this.events = response.data;
			});
		this.$pagesApi
			.events({
				typology: 1,
				per_page: 4,
				index_list: true,
			})
			.then((response) => {
				this.programs = response.data;
			});
	},
	methods: {
		...mapActions({
			showLoginPopup: 'authModal/showLogin',
			showRegistrationPopup: 'authModal/showRegistration',
		}),
		showModal(src) {
			this.$bvModal.show('intellect-property');
			this.modalIntellectPropertySrc = src;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/components/layouts/main-footer-panel";
@import "~/assets/styles/components/dev/modal-search";
</style>

<i18n lang="yaml">
ru:
  support-school: "Поддержать школу"
  about-school: "О школе"
  education: "Образование"
  academic: "Академическая платформа"
  alumni: "Выпускники"
  campus: "Кампус"
  events: "Мероприятия"
  media: "Медиа"
  history: "История"
  management: "Управление"
  accreditations-and-partnerships: "Аккредитация и партнерства"
  sustainability: "Развитие"
  helpful-information: "Полезная информация"
  contacts: "Контакты"
  educational-programs: "Образовательные программы"
  all-programs: "Все программы"
  event-announces: "Анонсы событий"
  all-events: "Все события"
  intellectual-property: "Интеллектуальная собственность"
  copyright: "Московская школа управления СКОЛКОВО. Все права защищены."
  InformationAboutOrganization: "Сведения об образовательной организации"
  careers: "Карьера"
  infrastructure: "Инфраструктура"
  accommodation: "Размещение гостей"
  pandemicSafety: "Постпандемическая безопасность"
  booking: "Бронирование номеров"
  academicCentres: "Центры исследований и экспертизы"
  library: "Библиотека"
  faculty: "Преподаватели"
en:
  support-school: "Support the School"
  about-school: "About the School"
  education: "Education"
  academic: "Academic Division"
  alumni: "Alumni"
  campus: "Campus"
  events: "Events"
  media: "Media"
  history: "History"
  management: "Governance"
  accreditations-and-partnerships: "Accreditations and partnerships"
  sustainability: "Sustainability"
  helpful-information: "Good to know"
  contacts: "Contacts"
  educational-programs: "Educational programmes"
  all-programs: "All programmes"
  event-announces: "Upcoming events"
  all-events: "All events"
  intellectual-property: "Intellectual property"
  copyright: "SKOLKOVO. All rights reserved."
  InformationAboutOrganization: "Information about the educational organization"
  careers: "Careers"
  infrastructure: "Infrastructure"
  accommodation: "Accommodation"
  pandemicSafety: "Post-pandemic safety"
  booking: "Booking"
  academicCentres: "Centers of Research & Expertise"
  library: "Library"
  faculty: "Professors"
</i18n>
