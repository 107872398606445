<!--Главная Антихрупкости-->
<template>
	<div>
		<header class="header">
			<div
				class="header_wrapper"
				:style="'background-image:url(https://www.skolkovo.ru/frontimg/campus_facts.jpg);'"
			>
				<div class="container mt-5 pt-lg-5 h-100">
					<div class="header_main h-100 d-flex align-items-end pb-5">
						<div>
							<h1 class="display-3 font-weight-bold mb-4">Мы обновляемся</h1>
							<div class="subtitle border-top mb-4 pt-4">
								Попробуйте обновить страницу позднее
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
		<main-footer />
	</div>
</template>

<script>
import MainFooter from '~/components/layouts/footers/MainFooter.vue';
import IssueMenu from '~/components/layouts/_partials/IssueMenu.vue';
import TopMenuNew from '~/components/layouts/_partials/TopMenuNew.vue';


export default {
	name: 'Maintenance',
	components: {
		MainFooter,
		IssueMenu,
		TopMenuNew,
	},
	props: {
		error: {
			type: Object,
			default: () => ({}),
		},
	},
	head() {
		return {
			title: 'Обновление',
		};
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/layouts/issue-header-panel";
	header.header {
		height: 100vh;
	}
	header.header .header_wrapper {
		height: 100%;
	}
</style>
