import Vue from 'vue';
const MEDIA_ROUTES = [
	'researches',
	'longread',
	'expert-opinions',
	'news',
	'interviews',
	'cases',
	'card',
	'unsorted',
	'unsorted',
	'notes',
	'qna',
];
Vue.use({
	// eslint-disable-next-line no-shadow
	install(Vue) {
		// eslint-disable-next-line no-param-reassign
		Vue.prototype.$url = {
			people(type, slug, lang = 'ru', options = {}) {
				const name = `${type}-detail${lang !== 'ru' ? '___en' : ''}`;
				const normalizeSlug = lang !== 'ru' ? slug.substr(0, slug.length - 3) : slug;

				return { name, params: { slug: normalizeSlug }, ...options };
			},
			conferences(issue, options = {}) {
				return { name: 'sp-issue-conference', params: { issue }, ...options };
			},
			conference(issue, slug, options = {}) {
				return { name: 'sp-issue-conference-slug', params: { issue, slug }, ...options };
			},
			events(options = {}) {
				return { name: 'event-list', ...options };
			},
			event(slug, typology, options = {}) {
				if (typology === 'events') {
					return { name: 'events-detail', params: { slug }, ...options };
				}

				if (typology === 'programmes') {
					return { name: 'programmes-detail', params: { slug }, ...options };
				}

				return { name: 'events-detail', params: { slug }, ...options };
			},
			news(slug, typology, options = {}) {
				return { name: 'news-detail', params: { slug }, ...options };
			},
			eventBroadcast(slug, typology, options = {}) {
				if (typology === 'events') {
					return { name: 'events-broadcast', params: { slug }, ...options };
				}

				if (typology === 'programmes') {
					return { name: 'programmes-broadcast', params: { slug }, ...options };
				}

				return { name: 'events-broadcast', params: { slug }, ...options };
			},
			media(slug, mediaTypeSlug, options = {}, lang = '') {
				if (lang === 'en') {
					if (mediaTypeSlug === 'researches') {
						return {
							name: 'researches-detail___en',
							params: { slug },
							...options,
						};
					}

					return {
						name: 'media-detail___en',
						params: {
							slug,
							mediaTypeSlug,
						},
						...options,
					};
				}

				const route = MEDIA_ROUTES.find((name) => [name].includes(mediaTypeSlug));

				if (route) {
					return {
						name: `${route}-detail`,
						params: { slug },
						...options,
					};
				}
			},
			mediaList(query) {
				return { name: 'article-section', query };
			},
			vacancy(id) {
				return { name: 'vacancy-inner-page', params: { id } };
			},
		};
	},
});
