const NAME_ITEM = 'constructor_preset';

export function state() {
	return {
		days: [
			{
				title: '1 день',
				active: true,
				id: 1,
			},
			{
				title: '2 день',
				active: true,
				id: 2,
			},
			{
				title: '3 день',
				active: false,
				id: 3,
			},
			{
				title: '4 день',
				active: false,
				id: 4,
			},
			{
				title: '5 день',
				active: false,
				id: 5,
			},
		],
		academic: [],
		selectedAcademicItems: [
			{ day: 1, values: {} },
			{ day: 2, values: {} },
			{ day: 3, values: {} },
			{ day: 4, values: {} },
			{ day: 5, values: {} },
		],
		disciplines: [],
		expert: [],
		selectedExpertItems: [
			{ day: 1, values: {} },
			{ day: 2, values: {} },
			{ day: 3, values: {} },
			{ day: 4, values: {} },
			{ day: 5, values: {} },
		],
		energy: [],
		selectedEnergyItems: [],
		validDays: [
			{ id: 1, valid: false },
			{ id: 2, valid: false },
			{ id: 3, valid: false },
			{ id: 4, valid: false },
			{ id: 5, valid: false },
		],
		countActiveDays: 2,
		parameters: '{}',
		preset: {},
	};
}

export const mutations = {
	SAVE_CONSTRUCTOR(state) {
		const param = JSON.parse(state.parameters);
		try {
			window.localStorage.setItem(NAME_ITEM, JSON.stringify({
				data: {
					blocks: {
						academic: state.selectedAcademicItems,
						expert: state.selectedExpertItems,
						energy: state.selectedEnergyItems,
					},
					parameters: param.additional_param ? param.additional_param : null,
				},
				description: 'описание',
				image_path: null,
				month_id: param.months ? param.months : null,
				name: 'пресет',
				online: param.status ? 1 : 0,
				participant_level_id: param.level ? param.level : null,
				participant_number_id: param.amount ? param.amount : null,
			}));
		} catch (e) {
			console.log(e);
		}
	},
	SET_ACADEMIC_BLOCK(state, items) {
		state.academic = items;
	},
	SET_DISCIPLINES(state, items) {
		state.disciplines = items;
	},
	SET_EXPERT_BLOCK(state, items) {
		state.expert = items;
	},
	SET_ENERGY_BLOCK(state, items) {
		state.energy = items;
	},
	SET_PRESET(state, preset) {
		state.preset = preset;
	},
	SET_SELECTED_ACADEMIC_BLOCK(state, item) {
		state.selectedAcademicItems[item.day].values = item.value;
	},
	FILTER_SELECTED_ACADEMIC(state, value) {
		if (
			(value.status && state.selectedAcademicItems[value.day].values.online !== 1)
				|| (!value.status && state.selectedAcademicItems[value.day].values.offline !== 1)
		) {
			state.selectedAcademicItems[value.day].values = {};
			state.selectedExpertItems[value.day].values = {};
			state.validDays[value.day].valid = false;
		}
	},
	SET_SELECTED_EXPERT_BLOCK(state, item) {
		if (item.value) {
			state.selectedExpertItems[item.day].values = item.value;
		}
	},
	SET_SELECTED_ENERGY_BLOCK(state, value) {
		if (value) {
			state.selectedEnergyItems = value;
		}
	},
	ADD_SELECTED_ENERGY_BLOCK(state, value) {
		state.selectedEnergyItems.push(value);
	},
	REMOVE_SELECTED_ENERGY_BLOCK(state, id) {
		state.selectedEnergyItems = state.selectedEnergyItems.filter((el) => el.id !== id);
	},
	FILTER_SELECTED_ENERGY(state, status) {
		if (status) {
			state.selectedEnergyItems = state.selectedEnergyItems.filter((el) => el.online === 1);
		} else {
			state.selectedEnergyItems = state.selectedEnergyItems.filter((el) => el.offline === 1);
		}
	},
	SET_SELECTED_SPORT_BLOCK(state, value) {
		if (value) {
			state.selectedSportItems = value;
		}
	},
	SET_PARAMETERS(state, value) {
		state.parameters = JSON.stringify(value);
	},
	ADD_DAY(state) {
		state.days[state.countActiveDays].active = true;
		state.countActiveDays += 1;
	},
	DELETE_DAY(state, day) {
		const countDays = state.selectedAcademicItems.findLast((el) => (!!el.values.id)).day - 1;
		for (let index = day; index < state.days.length - 1; index += 1) {
			state.selectedAcademicItems[index].values = state.selectedAcademicItems[index + 1].values;
			state.selectedExpertItems[index].values = state.selectedExpertItems[index + 1].values;
			state.validDays[index].valid = state.validDays[index + 1].valid;
			state.days[index].active = state.days[index + 1].active;
		}
		state.countActiveDays -= 1;
		if (countDays !== 1 && countDays >= day) {
			state.selectedAcademicItems[countDays].values = {};
			state.selectedExpertItems[countDays].values = {};
			state.validDays[countDays].valid = false;
			state.days[countDays].active = false;
		}
		if (countDays < day) {
			state.selectedAcademicItems[day].values = {};
			state.selectedExpertItems[day].values = {};
			state.validDays[day].valid = false;
			state.days[day].active = false;
		}
	},
	SET_ACADEM_EXPERT_FROM_PRESET(state, block) {
		const days = state.validDays;
		let item = null;
		days.forEach((el, index) => {
			if (block.name === 'academic') {
				state.selectedAcademicItems[index].values = block.value[el.id];
				if (block.value[el.id].id) {
					item = block.value[el.id];
					state.validDays[index].valid = true;
				}
			} else if (block.value[el.id]) {
				state.selectedExpertItems[index].values = block.value[el.id];
			}
		});
		if (block.name === 'academic') {
			const countDays = state.selectedAcademicItems.findLast((el) => el.values.id === item.id).day;
			for (let i = 3; i <= countDays; i++) {
				state.days[state.countActiveDays].active = true;
				state.countActiveDays += 1;
			}
		}
	},
	SET_ACADEM_EXPERT_FROM_LOCAL_STORAGE(state, block) {
		const days = state.validDays;
		let item = null;
		if (block.name === 'academic') {
			state.selectedAcademicItems = block.value;
			days.forEach((el, index) => {
				if (block.value[index].values.id) {
					item = block.value[index];
					state.validDays[index].valid = true;
				}
			});
			const countDays = item ? state.selectedAcademicItems.findLast((el) => el.values.id === item.values.id).day : 2;
			for (let i = 3; i <= countDays; i++) {
				state.days[state.countActiveDays].active = true;
				state.countActiveDays += 1;
			}
		} else {
			state.selectedExpertItems = block.value;
		}
	},
	SET_VALID_DAY(state, value) {
		state.validDays[value.day].valid = value.value;
	},
	RESET_ALL_BLOCKS(state) {
		for (let index = 0; index < state.days.length; index += 1) {
			state.selectedAcademicItems[index].values = {};
			state.selectedExpertItems[index].values = {};
			state.validDays[index].valid = false;
			if (index > 1) {
				state.days[index].active = false;
			}
		}
		state.selectedEnergyItems = [];
		state.selectedSportItems = [];
		state.countActiveDays = 2;
		// state.parameters = '{}';
	},
	CLEAR_STORE() {
		window.localStorage.removeItem(NAME_ITEM);
	},
};

export const actions = {
	async fetchAcademicBlock({ commit }, params) {
		const data = await this.$api.academicBlocks(params);
		commit('SET_ACADEMIC_BLOCK', data);
	},
	async fetchDisciplines({ commit }) {
		const data = await this.$api.disciplines();
		commit('SET_DISCIPLINES', data.data);
	},
	async fetchExpertBlock({ commit }) {
		const data = await this.$api.expertBlocks();
		commit('SET_EXPERT_BLOCK', data.data);
	},
	async fetchEnergyBlock({ commit }, param) {
		const data = await this.$api.energyBlocks(param);
		commit('SET_ENERGY_BLOCK', data.data);
	},
	async fetchPreset({ commit }, id) {
		const data = await this.$pagesApi.constructorPresetId(id);
		commit('SET_PRESET', data);
		commit('SET_PARAMETERS', {
			status: data.online === 1,
			amount: data.participant_number.id,
			level: data.participant_level.id,
			months: data.month.id,
			additional_param: data.parameters,
		});
		commit('SET_ACADEM_EXPERT_FROM_PRESET', { name: 'academic', value: data.blocks.academic });
		commit('SET_ACADEM_EXPERT_FROM_PRESET', { name: 'expert', value: data.blocks.expert });
		commit('SET_SELECTED_ENERGY_BLOCK', data.blocks.energy);
	},
	async filterAcademicBlock({ commit }, value) {
		const data = await this.$pagesApi.academicBlocksFiltered(value.id, value.status, value.page);
		commit('SET_ACADEMIC_BLOCK', data);
	},
	filterSelectedAcademic({ commit }, value) {
		commit('FILTER_SELECTED_ACADEMIC', value);
		commit('SAVE_CONSTRUCTOR');
	},
	getFromLocalStorage({ commit }) {
		const data = JSON.parse(window.localStorage.getItem(NAME_ITEM));
		commit('SET_PARAMETERS', {
			status: data.online === 1,
			amount: data.participant_number_id,
			level: data.participant_level_id,
			months: data.month_id,
			additional_param: data.data.parameters,
		});
		commit('SET_ACADEM_EXPERT_FROM_LOCAL_STORAGE', { name: 'academic', value: data.data.blocks.academic });
		commit('SET_ACADEM_EXPERT_FROM_LOCAL_STORAGE', { name: 'expert', value: data.data.blocks.expert });
		commit('SET_SELECTED_ENERGY_BLOCK', data.data.blocks.energy);
	},
	changeAcademicItem({ commit }, item) {
		commit('SET_SELECTED_ACADEMIC_BLOCK', item);
		commit('SAVE_CONSTRUCTOR');
	},
	changeExpertItem({ commit }, item) {
		commit('SET_SELECTED_EXPERT_BLOCK', item);
		commit('SAVE_CONSTRUCTOR');
	},
	changeEnergyItem({ commit }, value) {
		commit('SET_SELECTED_ENERGY_BLOCK', value);
		commit('SAVE_CONSTRUCTOR');
	},
	addEnergyItem({ commit }, value) {
		commit('ADD_SELECTED_ENERGY_BLOCK', value);
		commit('SAVE_CONSTRUCTOR');
	},
	removeEnergyItem({ commit }, value) {
		commit('REMOVE_SELECTED_ENERGY_BLOCK', value);
		commit('SAVE_CONSTRUCTOR');
	},
	filterSelectedEnergy({ commit }, status) {
		commit('FILTER_SELECTED_ENERGY', status);
		commit('SAVE_CONSTRUCTOR');
	},
	setParameters({ commit }, value) {
		commit('SET_PARAMETERS', value);
		commit('SAVE_CONSTRUCTOR');
	},
	changeValidationOfDay({ commit }, value) {
		commit('SET_VALID_DAY', value);
	},
	addDay({ commit }) {
		commit('ADD_DAY');
	},
	deleteDay({ commit }, day) {
		commit('DELETE_DAY', day);
		commit('SAVE_CONSTRUCTOR');
	},
	clearStore({ commit }) {
		commit('CLEAR_STORE');
	},
	resetAllBlocks({ commit }) {
		commit('RESET_ALL_BLOCKS');
		commit('SAVE_CONSTRUCTOR');
	},
};
