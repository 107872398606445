export const UNIQUE_ID_STORE_NAMESPACE = 'uniqueId';
export const ACTION_HANDLE_UNIQUE_ID = 'handleUniqueId';
export const MUTATION_SET_UNIQUE_ID = 'setUniqueId';
export const ACTION_SET_NULL_UNIQUE_ID = 'handleNullUniqueId';
export const UNIQUE_ID_COOKIE_NAME = 'skuid';

export const state = () => ({
	uniqueId: null,
});

export const mutations = {
	[MUTATION_SET_UNIQUE_ID](state, { uniqueId }) {
		state.uniqueId = uniqueId;
	},
};

export const actions = {
	async [ACTION_HANDLE_UNIQUE_ID]({ state, commit }, payload) {
		const { $cookies } = payload;

		let { uniqueId } = state;

		if (uniqueId !== null) {
			return uniqueId;
		}

		if (typeof $cookies.get(UNIQUE_ID_COOKIE_NAME) !== 'undefined') {
			uniqueId = $cookies.get(UNIQUE_ID_COOKIE_NAME);
			commit(MUTATION_SET_UNIQUE_ID, { uniqueId });
			return uniqueId;
		}

		try {
			uniqueId = await this.$axios.$get('/auth/unique-id');
			$cookies.set(UNIQUE_ID_COOKIE_NAME, uniqueId);
			commit(MUTATION_SET_UNIQUE_ID, { uniqueId });
		} catch (error) {
			// TODO: check error is 401 or throw
			// console.error(error);
		}

		return uniqueId;
	},
	async [ACTION_SET_NULL_UNIQUE_ID]({ commit }, payload) {
		const { $cookies } = payload;

		$cookies.delete(UNIQUE_ID_COOKIE_NAME);
		commit(MUTATION_SET_UNIQUE_ID, { uniqueId: null });
	},
};
