import {
	ACTION_SAVE_FORM_DATA_BACKEND,
	CHECKOUT_STORE_NAMESPACE,
	MUTATION_SET_FORM_META_DATA,
} from '@/store/checkout';

export const TYPE = {
	LOGIN: 0,
	REGISTRATION: 1,
	RESET_PASSWORD: 2,
	LOGIN_SIMPLE_WINDOW: 3,
};

export function state() {
	return {
		isVisible: false,
		type: TYPE.LOGIN,
		callSourceFormId: null,
		redirectUri: null,
		client: null,
	};
}

export const mutations = {
	show(state, type) {
		state.type = type;
		state.isVisible = true;
	},
	hide(state) {
		state.isVisible = false;
	},
	setIsVisible(state, isVisible) {
		state.isVisible = isVisible;
	},
	setCallSourceFormId(state, formId) {
		state.callSourceFormId = formId;
	},
	setRedirectUri(state, redirectUri) {
		state.redirectUri = redirectUri;
	},
	setClientState(state, value) {
		state.client = value;
	},
};

export const actions = {
	setRedirectUri({ commit }, redirectUri) {
		commit('setRedirectUri', redirectUri);
	},
	showSimpleLogin({ commit }) {
		commit('show', TYPE.LOGIN_SIMPLE_WINDOW);
	},
	showLogin({ commit }) {
		commit('show', TYPE.LOGIN);
	},
	showRegistration({ commit }) {
		commit('show', TYPE.REGISTRATION);
	},
	showResetPassword({ commit }) {
		commit('show', TYPE.RESET_PASSWORD);
	},
	hide({ commit, state, dispatch }) {
		commit('hide');

		if (state.callSourceFormId !== null) {
			commit(`${CHECKOUT_STORE_NAMESPACE}/${MUTATION_SET_FORM_META_DATA}`, {
				meta: {
					showLoginPopup: false,
				},
				formId: state.callSourceFormId,
			}, { root: true });

			dispatch(`${CHECKOUT_STORE_NAMESPACE}/${ACTION_SAVE_FORM_DATA_BACKEND}`, { formId: state.callSourceFormId }, { root: true });
		}
	},
};
