import Vue from 'vue';

export const state = () => ({
	streamId: null,
	result: [
	],
	isResults: false,
	programs: [],
});

export const actions = {
	async finishTest(ctx) {
		const answers = ctx.state.result.filter((res) => res.answers.length);
		await this.$api.updateAnswer({
			user_id: this.$auth.user.id,
			test_stream_id: ctx.state.streamId,
			result: answers,
		});
	},
	async fetchPrograms({ commit }, query) {
		const programs = await this.$pagesApi.getPrograms(query);
		commit('setPrograms', programs);
	},
};

export const mutations = {
	addAnswer(state, payload) {
		const answerIndex = state.result.findIndex((answer) => answer.id == payload.id);
		if (answerIndex + 1) {
			Vue.set(
				state.result,
				answerIndex,
				JSON.parse(JSON.stringify(payload)),
			);
		} else {
			state.result.push(payload);
		}
	},
	setStreamId(state, id) {
		state.streamId = id;
	},
	setIsResults(state, value) {
		state.isResults = value;
	},
	setPrograms(state, programs) {
		state.programs = programs;
	},
};

export const getters = {
	getResult(state) {
		return state.result;
	},
	getIsResults(state) {
		return state.isResults;
	},
	getPrograms(state) {
		return state.programs;
	},
};
