export function state() {
	return {
		isVisible: false,
	};
}

export const mutations = {
	show(state) {
		state.isVisible = true;
	},
	hide(state) {
		state.isVisible = false;
	},
	setIsVisible(state, isVisible) {
		state.isVisible = isVisible;
	},
};

export const actions = {
	show({ commit }) {
		commit('show');
	},
	hide({ commit }) {
		commit('hide');
	},
};
