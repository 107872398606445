<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 416 448">
		<title />
		<g id="icomoon-ignore" />
		<path
			fill="#fff"
			d="M160 384c0 17.5-14.5 32-32 32s-32-14.5-32-32 14.5-32 32-32 32 14.5 32 32zM384 384c0 17.5-14.5 32-32 32s-32-14.5-32-32 14.5-32 32-32 32 14.5 32 32zM416 112v128c0 8-6.25 15-14.25 16l-261 30.5c1.25 5.75 3.25 11.5 3.25 17.5 0 5.75-3.5 11-6 16h230c8.75 0 16 7.25 16 16s-7.25 16-16 16h-256c-8.75 0-16-7.25-16-16 0-7.75 11.25-26.5 15.25-34.25l-44.25-205.75h-51c-8.75 0-16-7.25-16-16s7.25-16 16-16h64c16.75 0 17.25 20 19.75 32h300.25c8.75 0 16 7.25 16 16z"
		/>
	</svg>
</template>

<script>
export default {
	name: 'CartSvg',
};
</script>
