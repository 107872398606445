import promiseScript from '~/utils/promiseScript';

const JIVO_BASE_SRC = '//code.jivosite.com/widget/';
const JIVO_DEFAULT_ID = 'lTPe1gNo2f';

export function state() {
	return {
		jivo: {
			loaded: false,
		},
		yandexChatLoaded: false,
	};
}

export const mutations = {
	jivoLoaded(state, code) {
		state.jivo.loaded = code;
	},
	yandexChatLoaded(state) {
		state.yandexChatLoaded = true;
	},
};

export const getters = {
	isJivoLoaded: (state) => !!state.jivo.loaded,
};

export const actions = {
	async loadJivo({ state, commit }, srcId) {
		const newSrcId = srcId !== undefined ? srcId : JIVO_DEFAULT_ID;
		if (!state.yandexChatLoaded) {
			commit('jivoLoaded', newSrcId);
			const src = JIVO_BASE_SRC + newSrcId;
			await promiseScript(src);
		}
	},
};
