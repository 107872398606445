import Bpm from '@/utils/Bpm';

// eslint-disable-next-line import/prefer-default-export
export const actions = {
	async nuxtServerInit({ state, dispatch }, { app, req }) {
		const { $api, $pagesApi, $cookies } = app;

		const setLangToDefaults = (axios) => {
			if (!axios.defaults.params) {
				// eslint-disable-next-line no-param-reassign
				axios.defaults.params = {};
			}
			// eslint-disable-next-line no-param-reassign
			axios.defaults.params.lang = this.$i18n.locale;
		};
		setLangToDefaults($api.axios);
		setLangToDefaults($pagesApi.axios);

		await dispatch('cookiesDisclaimer/init', $cookies);

		const protocol = req.connection.encrypted ? 'https' : 'http';
		const href = new URL(`${protocol}://${req.headers.host}${req.url}`);
		const ref = req.headers.referer ? new URL(req.headers.referer) : null;
		const bpm = new Bpm($cookies, href, ref);
		bpm.fillUtm();
	},
};
