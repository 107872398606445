import { render, staticRenderFns } from "./SpeechButton.vue?vue&type=template&id=027d5ac8"
import script from "./SpeechButton.vue?vue&type=script&lang=js"
export * from "./SpeechButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BIconMic, BButton} from 'bootstrap-vue'
    installComponents(component, {BIconMic, BButton})
    

export default component.exports