export function state() {
	return {
		products: [],
		cartCount: 0,
		totalSum: 0,
		count: 1,
		vat: false,
	};
}

export const mutations = {
	ADD_PRODUCT(state, product) {
		const itemIndex = state.products.find((p) => product.id === p.id);
		if (!itemIndex) {
			state.products.push({ ...product, count: 1 });
		} else {
			itemIndex.count = (itemIndex.count || 1) + 1;
		}
	},
	SET_PRODUCTS(state) {
		state.products = JSON.parse(localStorage.getItem('products')) || [];
	},
	REMOVE_PRODUCT(state, product) {
		const newList = JSON.parse(localStorage.getItem('products'));
		const index = newList.findIndex((item) => item.id === product.id);
		newList.splice(index, 1);
		localStorage.setItem('products', JSON.stringify(newList));
		state.products = newList;
	},
	COUNT_PRODUCTS(state, sum) {
		state.totalSum = sum;
	},
	REMOVE_FROM_LIST(state, product) {
		const item = state.products.find((p) => product.id === p.id);
		if (item.count > 1) {
			item.count -= 1;
		}
		localStorage.setItem('products', JSON.stringify(state.products));
	},
	SET_NEW_VALUE(state, products) {
		state.products = products;
	},

	SAVE_CART(state) {
		window.localStorage.setItem('products', JSON.stringify(state.products));
		// window.localStorage.setItem('cartCount', state.cartCount);
	},

	SET_VAT(state, vat) {
		state.vat = vat;
	}
};

export const actions = {
	async addProduct({ commit }, data) {
		commit('ADD_PRODUCT', data);
		commit('SAVE_CART');
	},
	async removeProduct({ commit }, productId) {
		await commit('REMOVE_PRODUCT', productId);
	},
	setProducts({ commit }) {
		commit('SET_PRODUCTS');
	},
	countPrice({ commit }, data) {
		commit('COUNT_PRODUCTS', data);
	},
	removeFromList({ commit }, data) {
		commit('REMOVE_FROM_LIST', data);
	},
	setNewValue({ commit }, data) {
		commit('SET_NEW_VALUE', data);
		commit('SAVE_CART');
	},
	returnVat({ commit }, data) {
		commit('SET_VAT', data)
	}
};
