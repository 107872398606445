export default async function ({ $auth, app }) {
	// Получаем JWT токен из cookie
	const token = app.$cookies.get('skolkovo_sso_jwt_token');
	if (token && !$auth.loggedIn) {
		try {
			// Запрашиваем информацию о пользователе, используя JWT токен
			const response = await app.$api.getUserSso({
				headers: {
					Authorization: `${token}`,
				},
			});
			const profile = response.data;
			const accessToken = response.token.access_token;
			const refreshToken = response.token.refresh_token;

			// Устанавливаем стратегию и токен для $auth
			await $auth.setStrategy('local');
			await $auth.setUserToken(accessToken);
			await $auth.setRefreshToken('local', refreshToken);
			await $auth.setUser(profile);

		} catch (error) {

		}
	} else if (!token) {
	}
}
