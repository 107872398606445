import qs from 'qs';
import * as https from 'https';
import Client from '../utils/api';

function paramsSerializer(params) {
	return qs.stringify(params, { arrayFormat: 'brackets', encode: false });
}

function makeApiOptions() {
	return {
		paramsSerializer,
		proxyHeaders: true,
		httpsAgent: new https.Agent({
			rejectUnauthorized: false,
		}),
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			Accept: 'application/json',
		},
	};
}

export default (context, inject) => {
	const { $axios, $sentry } = context;

	// eslint-disable-next-line no-param-reassign
	const apiAxios = $axios.create(makeApiOptions());
	const pagesApiAxios = $axios.create({
		...makeApiOptions(),
		progress: true,
	});

	const api = new Client(apiAxios);
	const pagesApi = new Client(pagesApiAxios);

	// onError, onResponseError helpers not working, so raw axios interceptors
	pagesApiAxios.interceptors.response.use(
		(r) => r,
		(e) => {
			if (process.env.SENTRY_DSN && $sentry) {
				$sentry.captureException(e);
			}

			const { response } = e;
			if (response) {
				const { status, statusText, data } = response;
				if (status !== 422) {
					context.$logger.exception(e, 'pagesApi');
				}
				if (data) {
					const message =						data.message || data.error || data.exception;
					context.error({ statusCode: status, message });
					return;
				}
				context.error({ statusCode: status, message: statusText });
				return;
			}

			context.$logger.exception(e, 'pagesApi');
			context.error({ statusCode: 500, message: 'Something went wrong' });
		},
	);

	inject('api', api);
	inject('pagesApi', pagesApi);
};
