import { render, staticRenderFns } from "./ResearchAlumniModal.vue?vue&type=template&id=62ad694a"
import script from "./ResearchAlumniModal.vue?vue&type=script&lang=js"
export * from "./ResearchAlumniModal.vue?vue&type=script&lang=js"
import style0 from "./ResearchAlumniModal.vue?vue&type=style&index=0&id=62ad694a&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BSpinner, BModal} from 'bootstrap-vue'
    installComponents(component, {BSpinner, BModal})
    

export default component.exports