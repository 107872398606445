<template>
	<div v-show="visible" v-scroll-to="'#__layout'" class="to_top_btn icon-arrow_up">
		{{ $t('up') }}
	</div>
</template>

<script>
export default {
	data() {
		return {
			visible: false,
		};
	},
	mounted() {
		window.addEventListener('scroll', this.scrollListener, { passive: true });
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.scrollListener);
	},
	methods: {
		scrollListener(e) {
			const offsetY = 500;
			this.visible = window.scrollY > offsetY;
		},
	},
};
</script>
<i18n>
{
	"ru": {
		"up": "наверх"
	},
	"en": {
		"up": "Up"
	}
}
</i18n>
