const middleware = {}

middleware['activated'] = require('../middleware/activated.js')
middleware['activated'] = middleware['activated'].default || middleware['activated']

middleware['auth-jwt'] = require('../middleware/auth-jwt.js')
middleware['auth-jwt'] = middleware['auth-jwt'].default || middleware['auth-jwt']

middleware['loyalty-b2b'] = require('../middleware/loyalty-b2b.js')
middleware['loyalty-b2b'] = middleware['loyalty-b2b'].default || middleware['loyalty-b2b']

export default middleware
