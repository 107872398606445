import _get from 'lodash/get';
import Vue from 'vue';
import getTargetAction from '~/utils/getTargetAction';

import { DEFAULT_FIELDS_TO_PROFILE_STEP_2, DEFAULT_FIELDS_TO_PROFILE_STEP_3 } from '@/components/checkout/profileToFieldSlugDictionary';

export const CHECKOUT_STORE_NAMESPACE = 'checkout';

export const MUTATION_INIT_FORM = 'initForm';
export const MUTATION_SET_STEP = 'setStep';
export const MUTATION_SET_FORM_META_DATA = 'setFormData';
export const MUTATION_SET_FORM_FIELDS_DATA = 'setFormFieldsData';
export const MUTATION_HARD_SET_FORM_FIELDS_DATA = 'hardSetFormFieldsData';
export const MUTATION_SET_VALIDATION_META = 'setValidationMeta';
export const MUTATION_SET_PRICE_DATA = 'setPriceData';
export const MUTATION_SET_LOCAL_META_DATA = 'setLocalMetaData';
export const MUTATION_REMOVE_PERSON_DATA = 'removePersonData';

export const ACTION_INIT_FORM = 'initForm';
export const ACTION_SAVE_FORM_DATA_BACKEND = 'saveFormDataToBackend';
export const ACTION_SET_STEP_AND_SYNC_FORM = 'setStepAndSyncForm';
export const ACTION_SAVE_ORDER = 'saveOrder';
export const ACTION_RESTORE_FORM_DATA_BACKEND = 'restoreFormDataFromBackend';
export const ACTION_SAVE_INITIAL_LEAD_DATA = 'saveInitialLeadData';
export const ACTION_GET_INVOICE_STATUS = 'getInvoiceStatus';
export const ACTION_SYNC_FROM_BACKEND = 'syncFromBackend';
export const ACTION_GET_PRICE_DATA = 'getPriceData';
export const ACTION_DELETE_PERSON = 'deletePerson';

export const state = () => ({
	/**
	 * Структура: состоит из двух свойств, meta и fields, внутри которых есть свойства, где
	 * название свойства - ID формы, а значения - объект со своими свойствами.
	 * ВНИМАНИЕ! Свойства в meta изменяются отдельными мутациями, свойства в fields меняются другой
	 * отдельной мутацией.
	 */
	formsData: {
		meta: {}, // то, что сохраняется на бэкенде в orders
		/**
		 * Использовать, когда состоянию не обязательно переживать перезагрузку.
		 * В InitForm следует определить свойство заранее, а так же дать ему значение по-умолчанию
		 */
		localMeta: {},
		fields: {},
		filledStep: {},
		validationMeta: {},
		priceData: {},
	},
});

export const mutations = {
	[MUTATION_INIT_FORM](state, { fo7rmId }) {
		Vue.set(state.formsData.meta, formId, {});
		Vue.set(state.formsData.localMeta, formId, {});
		Vue.set(state.formsData.fields, formId, {});
		Vue.set(state.formsData.filledStep, formId, 0);
		Vue.set(state.formsData.validationMeta, formId, {});
		Vue.set(state.formsData.priceData, formId, {});
	},
	[MUTATION_SET_STEP](state, { formId, step }) {
		const filledStep = Math.max(state.formsData.filledStep[formId] || 0, step);
		Vue.set(
			state.formsData.meta,
			formId,
			{ ...state.formsData.meta[formId], step },
		);
		Vue.set(state.formsData.filledStep, formId, filledStep);
	},
	[MUTATION_SET_FORM_META_DATA](state, { meta, formId }) {
		Vue.set(state.formsData.meta, formId, {
			...state.formsData.meta[formId],
			...meta,
		});
	},
	[MUTATION_SET_FORM_FIELDS_DATA](state, { fields, formId }) {
		const newFields = {
			...state.formsData.fields,
			[formId]: {
				...(state.formsData.fields[formId] || {}),
			},
		};
		Object.keys(fields).forEach((personNumber) => {
			newFields[formId][personNumber] = {
				...(newFields[formId][personNumber] || {}),
				...fields[personNumber],
			};
		});
		Vue.set(state.formsData, 'fields', newFields);
	},
	[MUTATION_HARD_SET_FORM_FIELDS_DATA](state, { fields, formId }) {
		Vue.set(state.formsData.fields, formId, fields);
	},
	[MUTATION_SET_VALIDATION_META](state, { formId, step, isValidate }) {
		Vue.set(state.formsData.validationMeta, formId, {
			...state.formsData.validationMeta[formId],
			[step]: isValidate,
		});

		if (!isValidate) {
			const filledStep = Math.max(step - 1, 0);
			Vue.set(state.formsData.filledStep, formId, filledStep);
		}
	},
	[MUTATION_SET_PRICE_DATA](state, { priceData, formId }) {
		Vue.set(state.formsData.priceData, formId, {
			...state.formsData.priceData[formId],
			...priceData,
		});
	},
	[MUTATION_SET_LOCAL_META_DATA](state, { localMeta, formId }) {
		Vue.set(state.formsData.localMeta, formId, {
			...state.formsData.localMeta[formId],
			...localMeta,
		});
	},
	[MUTATION_REMOVE_PERSON_DATA](state, { formId, personNumber }) {
		Vue.delete(state.formsData.fields[formId], personNumber);
	},
};

export const actions = {
	async [ACTION_SAVE_FORM_DATA_BACKEND]({ state, rootState }, { formId, eventFormScenario }) {
		if (!formId) {
			console.error('No formId');
		}

		const filledStep = Math.max(
			state.formsData.filledStep[formId] || 1,
			state.formsData.meta[formId]?.step || 1,
		);

		let personCount = 0;
		if (state.formsData.fields.hasOwnProperty(formId)) {
			Object.keys(state.formsData.fields[formId]).forEach(() => {
				personCount += 1;
			});
		}

		return this.$api.checkoutFormSave({
			formId,
			uniqueId: rootState.uniqueId.uniqueId,
			meta: {
				...state.formsData.meta[formId],
				step: filledStep,
				personCount,
				sklTargetAction: 'Оплата',
			},
			fields: state.formsData.fields[formId] || {},
		});
	},
	async [ACTION_SAVE_ORDER]({ state, rootState, commit }, { formId }) {
		commit(MUTATION_SET_FORM_META_DATA, {
			formId,
			meta: {
				isOrderExists: true,
			},
		});

		await this.$api.saveOrder({
			formId,
			uniqueId: rootState.uniqueId.uniqueId,
			meta: { ...state.formsData.meta[formId] },
			fields: state.formsData.fields[formId] || {},
		});
	},
	async [ACTION_SET_STEP_AND_SYNC_FORM]({ commit, dispatch }, { formId, step, event }) {
		await dispatch(ACTION_SAVE_FORM_DATA_BACKEND, { formId, event });
		await commit(MUTATION_SET_STEP, { formId, step });
	},
	async [ACTION_INIT_FORM]({ rootState, commit, dispatch }, { form, event, sellablePrograms }) {
		let product = sellablePrograms[0];
		const restoredFormResponse = await this.$api.checkoutFormGetFilledData({
			formId: form.id,
			uniqueId: rootState.uniqueId.uniqueId,
		});
		const restoredForm = restoredFormResponse.data;

		let step = 1;

		if (restoredForm) {
			step = restoredForm.step;
		}

		// Устанавливаем isValidate для предыдущих шагов
		for (let i = 1; i < step; i++) {
			commit(MUTATION_SET_VALIDATION_META, {
				formId: form.id, step: i, isValidate: true,
			});
		}

		// Берем поля из формы, пришедшей с бэка
		const fields = {};
		let meta = {};
		if (restoredForm) {
			meta = {
				orderId: restoredForm.id,
				productId: restoredForm.product_id,
				eventId: restoredForm.event_id,
				createdAt: restoredForm.created_at,
				personCount: restoredForm.person_count,
				scenario: restoredForm.scenario,
				isRegistrant: restoredForm.is_registrant,
				contractSign: restoredForm.contract_sign,
				showLoginPopup: restoredForm.show_login_popup,
				showPaymentPopup: restoredForm.show_payment_popup,
				isInitialLeadSent: restoredForm.is_initial_lead_sent,
				isInvoiceCreated: restoredForm.is_invoice_created,
				invoiceStatus: restoredForm.invoice_status,
				isRegistrantDisclaimerDisplayed: restoredForm.is_registrant_disclaimer_displayed,
				signerPersonNumber: restoredForm.signer_person_number,
				skGaClientId: restoredForm.sk_ga_client_id,
				skYmClientId: restoredForm.sk_ym_client_id,
				bpmRef: restoredForm.bpm_ref,
				bpmHref: restoredForm.bpm_href,
				disagreementReason: restoredForm.disagreement_reason,
				isStudent: restoredForm.is_student,
				isContactManager: restoredForm.is_contact_manager,
				contactManagerReason: restoredForm.contact_manager_reason,
				approvalStatus: restoredForm.approval_status,
				isInvoiceTypeInvoice: restoredForm.is_invoice_type_invoice,
			};

			for (const sellableProgram of sellablePrograms) {
				if (sellableProgram.id === meta.productId) {
					product = sellableProgram;
				}
			}

			restoredForm.fields.forEach((field) => {
				if (typeof fields[field.person_number] === 'undefined') {
					fields[field.person_number] = {};
				}

				fields[field.person_number][field.slug] = {
					personNumber: field.person_number,
					value: field.value,
					formFieldId: field.form_field_id,
					additionalData: field.additional_data,
				};
			});

			meta.personCount = restoredForm.fields.length;
		} else {
			meta = {
				personCount: 1,
				showLoginPopup: true,
				showPaymentPopup: true,
				isInitialLeadSent: false,
				isRegistrantDisclaimerDisplayed: false,
				signerPersonNumber: null,
				isStudent: false,
				isContactManager: false,
			};
		}

		if (typeof fields[1] === 'undefined') {
			fields[1] = {};
		}
		const { user } = this.$auth;
		if (user) {
			/* Заполняем стандартные поля для второго шага из профиля */
			Object.entries(DEFAULT_FIELDS_TO_PROFILE_STEP_2).forEach(([field, key]) => {
				if (typeof fields[1][field] !== 'undefined' || typeof form.fields[2][field] === 'undefined') {
					return;
				}

				const value = _get(user, key);
				if (value) {
					fields[1][field] = {
						personNumber: 1,
						value,
						formFieldId: form.fields[2][field].form_field_id,
						additionalData: [],
					};
				}
			});
			Object.entries(DEFAULT_FIELDS_TO_PROFILE_STEP_3).forEach(([field, key]) => {
				if (typeof fields[1][field] !== 'undefined' || typeof form.fields[3][field] === 'undefined') {
					return;
				}

				const value = _get(user, key);
				if (value) {
					fields[1][field] = {
						personNumber: 1,
						value,
						formFieldId: form.fields[3][field].form_field_id,
						additionalData: [],
					};
				}
			});
		}

		commit(MUTATION_SET_STEP, {
			formId: form.id,
			step,
		});

		commit(MUTATION_SET_FORM_FIELDS_DATA, {
			formId: form.id,
			fields,
		});

		commit(MUTATION_SET_FORM_META_DATA, {
			formId: form.id,
			meta: {
				...meta,
				productId: product.id,
				eventId: event.id,
			},
		});

		commit(MUTATION_SET_LOCAL_META_DATA, {
			formId: form.id,
			localMeta: {
				isShowDocumentInfoMessage: false,
				isInvoicePayment: false,
				product,
			},
		});

		const saveResponse = await dispatch(ACTION_SAVE_FORM_DATA_BACKEND, { formId: form.id });

		if (saveResponse.success === false) {
			console.log('Ошибка при сохранении данных формы');
		}

		await dispatch(ACTION_GET_PRICE_DATA, { formId: form.id, uniqueId: rootState.uniqueId.uniqueId });

		commit(MUTATION_SET_FORM_META_DATA, {
			formId: form.id,
			meta: {
				createdAt: saveResponse.data.created_at,
				orderId: saveResponse.data.id,
			},
		});
	},
	async [ACTION_SAVE_INITIAL_LEAD_DATA](
		{ rootState, commit, dispatch }, { formId, select_landing_id, step },
	) {
		await this.$api.saveInitialLead({
			formId,
			uniqueId: rootState.uniqueId.uniqueId,
			select_landing_id,
			need_register_loyalty: rootState.checkout.formsData.meta[formId].needRegisterLoyalty,
		});
		await commit(MUTATION_SET_FORM_META_DATA, {
			formId,
			meta: {
				isInitialLeadSent: true,
			},
		});
		if (step) {
			await commit(MUTATION_SET_STEP, { formId, step });
		}
		await dispatch(ACTION_SAVE_FORM_DATA_BACKEND, { formId });
	},
	async [ACTION_GET_INVOICE_STATUS]({ rootState, commit }, { formId }) {
		const response = await this.$api.getInvoiceStatus({
			formId,
			uniqueId: rootState.uniqueId.uniqueId,
		});

		commit(MUTATION_SET_FORM_META_DATA, {
			formId,
			meta: {
				invoiceStatus: response.invoice_status,
			},
		});
	},
	async [ACTION_GET_PRICE_DATA]({ rootState, commit }, { formId }) {
		const response = await this.$api.getPriceData({
			formId,
			uniqueId: rootState.uniqueId.uniqueId,
		});

		commit(MUTATION_SET_PRICE_DATA, {
			uniqueId: rootState.uniqueId.uniqueId,
			formId,
			priceData: response,
		});
	},
	async [ACTION_SYNC_FROM_BACKEND]({ rootState, commit, dispatch }, { form, product }) {
		const formData = await this.$api.checkoutFormGetFilledData({
			formId: form.id,
			uniqueId: rootState.uniqueId.uniqueId,
		});

		if (formData === false) {
			return;
		}

		const meta = {
			orderId: formData.id,
			productId: formData.product_id,
			createdAt: formData.created_at,
			personCount: formData.person_count,
			scenario: formData.scenario,
			isRegistrant: formData.is_registrant,
			contractSign: formData.contract_sign,
			showLoginPopup: formData.show_login_popup,
			showPaymentPopup: formData.show_payment_popup,
			isInitialLeadSent: formData.is_initial_lead_sent,
			isInvoiceCreated: formData.is_invoice_created,
			invoiceStatus: formData.invoice_status,
			isRegistrantDisclaimerDisplayed: formData.is_registrant_disclaimer_displayed,
			signerPersonNumber: formData.signer_person_number,
			skGaClientId: formData.sk_ga_client_id,
			skYmClientId: formData.sk_ym_client_id,
			bpmRef: formData.bpm_ref,
			bpmHref: formData.bpm_href,
			disagreementReason: formData.disagreement_reason,
			isStudent: formData.is_student,
			isContactManager: formData.is_contact_manager,
			contactManagerReason: formData.contact_manager_reason,
		};
		const fields = [];

		formData.fields.forEach((field) => {
			if (typeof fields[field.person_number] === 'undefined') {
				fields[field.person_number] = {};
			}

			fields[field.person_number][field.slug] = {
				personNumber: field.person_number,
				value: field.value,
				formFieldId: field.form_field_id,
				additionalData: field.additional_data,
			};
		});

		commit(MUTATION_SET_FORM_FIELDS_DATA, {
			formId: form.id,
			fields,
		});

		commit(MUTATION_SET_FORM_META_DATA, {
			formId: form.id,
			meta: {
				...meta,
				productId: product.id,
			},
		});

		await dispatch(ACTION_SAVE_FORM_DATA_BACKEND, { formId: form.id });
	},
	async [ACTION_DELETE_PERSON]({ commit, state }, { formId, personNumber }) {
		await commit(MUTATION_REMOVE_PERSON_DATA, { formId, personNumber });
		const rearrangedFields = {};
		let personCounter = 1;
		Object.keys(state.formsData.fields[formId]).forEach((key) => {
			rearrangedFields[personCounter] = JSON.parse(JSON.stringify(state.formsData.fields[formId][key]));
			Object.keys(rearrangedFields[personCounter]).forEach((fieldName) => {
				rearrangedFields[personCounter][fieldName].personNumber = personCounter;
			});
			personCounter += 1;
		});
		await commit(MUTATION_HARD_SET_FORM_FIELDS_DATA, { formId, fields: rearrangedFields });
	},
};

export const getters = {
	getIsShowDocumentInfoMessage: (state) => (formId) => state.formsData.localMeta[formId]?.isShowDocumentInfoMessage,
	getIsInvoicePayment: (state) => (formId) => state.formsData.localMeta[formId]?.isInvoicePayment,
	getProduct: (state) => (formId) => state.formsData.localMeta[formId]?.product,
	getIsInvoiceTypeInvoice: (state) => (formId) => state.formsData.meta[formId]?.isInvoiceTypeInvoice,
	getIsContactManager: (state) => (formId) => state.formsData.meta[formId]?.isContactManager,
	getPriceData: (state) => (formId) => state.formsData.priceData[formId],
	getIsStudent: (state) => (formId) => state.formsData.meta[formId]?.isStudent,
	getCreatedAt: (state) => (formId) => state.formsData.meta[formId]?.createdAt,
	getOrderId: (state) => (formId) => state.formsData.meta[formId]?.orderId,
	getOrderSatus: (state) => (formId) => state.formsData.meta[formId]?.approvalStatus,
	getIsRegistrantDisclaimerDisplayed: (state) => (formId) => state.formsData.meta[formId]?.isRegistrantDisclaimerDisplayed,
	getSignerPersonNumber: (state) => (formId) => state.formsData.meta[formId]?.signerPersonNumber,
	getInvoiceStatus: (state) => (formId) => state.formsData.meta[formId]?.invoiceStatus,
	getIsInvoiceCreated: (state) => (formId) => state.formsData.meta[formId]?.isInvoiceCreated,
	getIsInitialLeadSent: (state) => (formId) => state.formsData.meta[formId]?.isInitialLeadSent,
	getIsRegistrant: (state) => (formId) => state.formsData.meta[formId]?.isRegistrant,
	getStep: (state) => (formId) => state.formsData.meta[formId]?.step,
	getScenario: (state) => (formId) => state.formsData.meta[formId]?.scenario,
	getContractSign: (state) => (formId) => state.formsData.meta[formId]?.contractSign,
	getIsShowLoginPopup: (state) => (formId) => state.formsData.meta[formId]?.showLoginPopup,
	getIsShowPaymentPopup: (state) => (formId) => state.formsData.meta[formId]?.showPaymentPopup,
	getFilledStep: (state) => (formId) => state.formsData.filledStep[formId],
	isValidateStep: (state) => (formId, step) => state.formsData.validationMeta[formId]?.[step],
	getIsOrderExists: (state) => (formId) => state.formsData.meta[formId]?.isOrderExists,
	getFullName: (state) => (formId, personNumber = 1) => {
		const person = state.formsData.fields[formId]?.[personNumber] || {};
		const fullName = `${person.first_name?.value || ''} ${person.last_name?.value || ''}`;
		return fullName.trim() ? fullName : 'Заполните информацию об участнике';
	},
	getRoleName: (state, getters) => (formId, personNumber = 1) => {
		if (personNumber === 1 && getters.getIsRegistrant(formId)) {
			return 'Ассистент';
		}

		if (getters.getSignerPersonNumber(formId) === personNumber) {
			return 'Студент (подписант)';
		}

		return 'Студент';
	},
	getFormFields: (state) => (formId, personNumber = 1) => {
		const fields = state.formsData.fields[formId]?.[personNumber];

		if (!fields) return {};

		return Object.keys(fields).reduce((result, item) => {
			result[item] = fields[item]?.value || null;
			return result;
		}, {});
	},
	getFieldValue(state) {
		const { fields } = state.formsData;
		return ({ formId, slug, personNumber = 1 }) => {
			const formFields = fields[formId];
			if (!formFields) return null;

			const personFields = formFields[personNumber];
			if (!personFields) return null;

			return personFields[slug]?.value || null;
		};
	},
	getFieldAdditionalData: (state) => ({ formId, slug, personNumber = 1 }) => {
		if (
			!(formId in state.formsData.fields) || (!(personNumber in state.formsData.fields[formId]))
		) {
			return null;
		}

		for (const field in state.formsData.fields[formId][personNumber]) {
			if (state.formsData.fields[formId][personNumber].hasOwnProperty(field) === false) {
				return null;
			}

			if (field === slug) {
				return state.formsData.fields[formId][personNumber][field].additionalData;
			}
		}

		return null;
	},
	getAdditionalPersonsCount: (state) => (formId) => {
		let counter = 0;

		if (!state.formsData.fields.hasOwnProperty(formId)) {
			return counter;
		}

		Object.keys(state.formsData.fields[formId]).forEach((personNumber) => {
			if (personNumber > 1) {
				counter++;
			}
		});

		return counter;
	},
};
