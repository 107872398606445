export default function loyaltyB2b({ $auth, route, redirect }) {
	if ($auth.loggedIn) {
		const { user } = $auth;

		if (
			route.path.startsWith('/user/profile/loyalty-b2b')
			&& user
			&& !user.is_corporate_client
		) {
			redirect('/user/profile/loyalty');

			return;
		}

		if (
			route.path.startsWith('/user/profile/loyalty')
			&& !route.path.startsWith('/user/profile/loyalty-b2b')
			&& user
			&& user.is_corporate_client
		) {
			redirect('/user/profile/loyalty-b2b');
		}
	}
}
