<template>
	<div>
		<nuxt />
	</div>
</template>

<script>

export default {
	name: 'DefaultAmpLayout',
	head() {
		return {};
	},
};
</script>

<style lang="scss">
	@import "common_style";
</style>
