<template>
	<svg
		id="Слой_1"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0"
		y="0"
		viewBox="0 0 560 534"
		style="enable-background:new 0 0 560 534"
		xml:space="preserve"
	><style>.st0{clip-path:url(#SVGID_00000152247636208194105370000011876292100103212196_)}</style><defs><path id="SVGID_1_" d="M0 0h560v534H0z" /></defs><clipPath id="SVGID_00000088850033528776256250000001559473184157307294_"><use xlink:href="#SVGID_1_" style="overflow:visible" /></clipPath><g style="clip-path:url(#SVGID_00000088850033528776256250000001559473184157307294_)"><path d="M92 24.6c123.2-32.8 252.8-32.8 376 0 41.3 11 72.9 44.8 81.1 86.7 14.5 74.4 14.5 150.9 0 225.3-8.2 42-39.8 75.8-81.1 86.8-3.1.8-6.3 1.6-9.4 2.4-6.2 1.6-10.6 7.2-10.6 13.6v88.9c0 2.2-1.3 4.2-3.2 5.1-2 .9-4.3.6-6-.8L348.1 456c-7.6-6.5-17.5-9.7-27.5-9.2-77 4.3-154.1-3.6-228.6-23.4-41.3-11-72.9-44.8-81.1-86.8-14.5-74.4-14.5-150.9 0-225.3 8.2-42 39.8-75.7 81.1-86.7zm304.7 74.6c8.4 0 15.3 6.8 15.3 15.3v53.4c0 8.4-6.8 15.3-15.3 15.3h-53.4c-8.4 0-15.3 6.8-15.3 15.3v144.9c0 8.4-6.8 15.3-15.3 15.3h-61c-8.4 0-15.3-6.8-15.3-15.3V198.3c0-8.4-6.8-15.3-15.3-15.3h-53.4c-8.4 0-15.3-6.8-15.3-15.3v-53.4c0-8.4 6.8-15.3 15.3-15.3h229z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#fff" /></g></svg>
</template>

<script>
export default {
	name: 'TenChat',
};
</script>
