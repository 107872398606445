<!--Главная Антихрупкости-->
<template>
	<div>
		<header class="header">
			<div
				class="header_wrapper"
				:style="'background-image:url(//sk.skolkovo.ru/img/2d818076-e22b-4e17-b362-1fc3d14e2894/404.jpg?fm=jpg&q=80&fit=crop&crop=2560%2C948%2C0%2C0&w=2560&h=948);'"
			>
				<top-menu-new/>
				<div class="container mt-5 pt-lg-5 h-100">
					<div class="header_main h-100 d-flex align-items-end pb-5">
						<div>
							<h1 class="display-1 font-weight-bold mb-4">404</h1>
							<div class="subtitle border-top w-50 mb-4 pt-4">
								Страница не найдена
							</div>
							<p class="mb-4">
								Похоже, страница не найдена, но мы всегда на связи. «СКОЛКОВО. На связи» — специальный проект для всех, кто хочет понять, как управлять бизнесом, процессами и командами в стремительно меняющемся мире
							</p>
							<a href="/longread/skolkovo-na-svyazi/" class="btn btn-primary">
								Читайте подробности и смотрите эфиры проекта
							</a>
						</div>
					</div>
				</div>
			</div>
		</header>
		<main-footer />
	</div>
</template>

<script>
import MainFooter from '~/components/layouts/footers/MainFooter.vue';
import IssueMenu from '~/components/layouts/_partials/IssueMenu.vue';
import TopMenuNew from '~/components/layouts/_partials/TopMenuNew.vue';


export default {
	name: 'Error404',
	components: {
		MainFooter,
		IssueMenu,
		TopMenuNew,
	},
	props: {
		error: {
			type: Object,
			default: () => {},
		},
	},

	head() {
		return {
			title: '404',
		};
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/layouts/issue-header-panel";
	header.header {
		height: 100vh;
	}
	header.header .header_wrapper {
		height: 100%;
	}
</style>
