<template>
	<div id="cookies-eu-banner" class="cookies-eu-banner">
		<div class="container">
			<div class="row align-items-center mb-0">
				<div class="col-12 col-lg-8">
					<p class="small mb-3 mb-lg-0">
						Мы используем файлы cookie чтобы сделать сайт еще удобнее для Вас. Оставаясь с нами, вы <a target="_blank" href="https://www.skolkovo.ru/static/cookie_rus.html">соглашаетесь на обработку файлов cookie</a>
					</p>
				</div>
				<div class="col-12 col-lg-4 d-flex justify-content-lg-center">
					<button
						id="cookies-eu-accept"
						class="btn btn-lg btn-primary rounded-pill"
						:class="['cookie-bounce']"
						@click="$emit('accept')"
					>
						{{ $t('accept') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Cookies',
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/dev/cookies";


.cookie-bounce{
  animation: cookie-bounce 0.9s 0.4s ease 1;
}

@keyframes cookie-bounce {
  30% { transform: scale(1.05); }
  40%, 60% { transform: rotate(-5deg) scale(1.05); }
  50% { transform: rotate(5deg) scale(1.05); }
  70% { transform: rotate(0deg) scale(1.05); }
  100% { transform: scale(1); }
}
</style>

<i18n>
{
	"ru": {
		"accept": "Принять",
		"cookies-disclaimer": "Пожалуйста, подключите файлы cookies в настройках вашего браузера для корректной работы сервисов сайта."
	},
	"en": {
		"accept": "Accept",
		"cookies-disclaimer": "Please, enable cookies in your browser settings for valid work of site services."
	}
}
</i18n>
