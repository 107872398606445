export const state = () => ({
	triedToSend: false,
	errors: null
});

export const mutations = {
	changeTriedToSend(state, isTried) {
		state.triedToSend = isTried;
	},
};

export const getters = {
	getTriedToSend: (state) => state.triedToSend,
}
