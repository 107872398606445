import promiseScript from '~/utils/promiseScript';
import promiseStyle from '~/utils/promiseStyle';

const BVI = '/button-visually-impaired-javascript-master/dist';
const BVI_CSS = `${BVI}/css/bvi.min.css`;
const BVI_COOKIE = `${BVI}/js/js.cookie.min.js`;
const BVI_JS = `${BVI}/js/bvi.min.js`;

function init($) {
	$.bvi({
		bvi_target: '.bvi-open', // Класс ссылки включения плагина
		bvi_theme: 'white', // Цвет сайта
		bvi_font: 'arial', // Шрифт
		bvi_font_size: 16, // Размер шрифта
		bvi_letter_spacing: 'normal', // Межбуквенный интервал
		bvi_line_height: 'normal', // Междустрочный интервал
		bvi_images: true, // Изображения
		bvi_reload: false, // Перезагрузка страницы при выключении плагина
		bvi_fixed: false, // Фиксирование панели для слабовидящих вверху страницы
		bvi_tts: true, // Синтез речи
		bvi_flash_iframe: true, // Встроенные элементы (видео, карты и тд.)
		bvi_hide: false, // Скрывает панель для слабовидящих и показывает иконку панели.
	});
}

export function state() {
	return {
		isLoaded: false,
	};
}

export const mutations = {
	load(state) {
		state.isLoaded = true;
	},
};

export const actions = {
	async load({ state, commit }, target) {
		if (!state.isLoaded) {
			const jq = window.$ || window.jQuery || await (await import(/* webpackChunkName: "jquery" */'jquery')).default;
			window.$ = jq;
			window.jQuery = jq;

			await Promise.all([
				promiseStyle(BVI_CSS),
				promiseScript(BVI_COOKIE),
				promiseScript(BVI_JS),
			]);

			commit('load');
			init(jq);

			const close = document.getElementById('bvi-panel-close');
			if (!close) {
				target.click();
			}
		}
	},
};
