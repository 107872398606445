export default (src, code, async = true) => new Promise((resolve, reject) => {
	if (document.querySelector('jdiv')) {
		// eslint-disable-next-line no-undef
		jivo_destroy();
	}
	const script = document.createElement('script');
	script.id = 'jivo-script';
	script.type = 'application/javascript';

	script.onload = resolve;
	script.onerror = reject;
	script.onreadystatechange = function onReadyStateChange() {
		if (this.readyState === 'complete' || this.readyState === 'loaded') {
			setTimeout(this.onload, 0);
		}
	};

	script.async = async;

	if (src) {
		script.src = src;
	}

	if (code) {
		script.innerHTML = code;
	}

	document.head.appendChild(script);
});
