export function state() {
	return {
		alumni: false,
		panelMediaPagination: false,
		panelDynamicMedia: false,
		panelDynamicEvents: false,
		panelDynamicEventsAnn: false,
		panelDynamicMedias: false,
		panelDynamicUsefulContent: false,
		panelDynamicUsefulContentNoTabs: false,
		eventInnerPage: false,
		eventPreview: false,
		eventTest: false,
		eventBroadcast: false,
		projectAlumni: false,
		projectSuccess: false,
		alumniInnerPage: false,
		projectArticle: false,
		requestSuccess: false,
		eventDetail: false,
		partnerEventDetail: false,
		partnerMaterialDetail: false,
		articleSection: false,
		longread: false,
		extendedReg: false,
		research: false,
	};
}

export const mutations = {
	alumni(state, value) {
		state.alumni = value;
	},
	panelMediaPagination(state, value) {
		state.panelMediaPagination = value;
	},
	panelDynamicMedia(state, value) {
		state.panelDynamicMedia = value;
	},
	panelDynamicEvents(state, value) {
		state.panelDynamicEvents = value;
	},
	panelDynamicEventsAnn(state, value) {
		state.panelDynamicEventsAnn = value;
	},
	panelDynamicMedias(state, value) {
		state.panelDynamicMedias = value;
	},
	panelDynamicUsefulContent(state, value) {
		state.panelDynamicUsefulContent = value;
	},
	panelDynamicUsefulContentNoTabs(state, value) {
		state.panelDynamicUsefulContentNoTabs = value;
	},
	eventInnerPage(state, value) {
		state.eventInnerPage = value;
	},
	eventPreview(state, value) {
		state.eventPreview = value;
	},
	eventTest(state, value) {
		state.eventTest = value;
	},
	eventBroadcast(state, value) {
		state.eventBroadcast = value;
	},
	projectAlumni(state, value) {
		state.projectAlumni = value;
	},
	projectSuccess(state, value) {
		state.projectSuccess = value;
	},
	alumniInnerPage(state, value) {
		state.alumniInnerPage = value;
	},
	projectArticle(state, value) {
		state.projectArticle = value;
	},
	requestSuccess(state, value) {
		state.requestSuccess = value;
	},
	eventDetail(state, value) {
		state.eventDetail = value;
	},
	partnerEventDetail(state, value) {
		state.partnerEventDetail = value;
	},
	partnerMaterialDetail(state, value) {
		state.partnerMaterialDetail = value;
	},
	articleSection(state, value) {
		state.articleSection = value;
	},
	longread(state, value) {
		state.longread = value;
	},
	extendedReg(state, value) {
		state.extendedReg = value;
	},
	research(state, value) {
		state.research = value;
	},
};
